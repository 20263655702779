import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DashboardService } from '../base/services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { DatePipe } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../components/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  providers: [DatePipe]
})
export class ProfileComponent implements OnInit {
  id: string;
  AllEmployeeCode: any;
  message: string;
  errorMessage: any;
  updateEmployeeCode: FormGroup;
  updateTechnology: FormGroup;
  updatePreviousEmployee: FormGroup;
  submitted = false;
  empsubmitted = false;
  techsubmitted = false;
  user: string;
  AllTechnologies: any;
  EmplooyeeTechnology: any;

  p: number = 1;
  AllTechnologiesList: any;
  relive_date: any = '';
  dob: any = '';
  employer_details: any;

  constructor(public dialog: MatDialog, private modalService: BsModalService, private datePipe: DatePipe, private flashMessage: FlashMessagesService, private formBuilder: FormBuilder, private dashboardService: DashboardService, private route: ActivatedRoute, private router: Router) {
    this.id = localStorage.getItem('id');
  }

  ngOnInit() {

    this.AllEmployee();
    // this.AllTechnology();
    this.employeeDetails(this.id);
 

    this.updateEmployeeCode = this.formBuilder.group({
      employee_code: [{ value: '', disabled: true }, [Validators.required,
      Validators.pattern("^[0-9]*$")]],
      user: [{ value: '', disabled: true }, Validators.required],
      date: [{ value: '', disabled: true }, Validators.required],
      designation: [{ value: '', disabled: true }, Validators.required],
      updated_by: this.user,
      gender: [{ value: '', disabled: true }],
      marital_status: [{ value: '', disabled: true }],
      date_relieved: [{ value: '', disabled: true }],
      tel1: [{ value: '', disabled: true }],
      tel2: [{ value: '', disabled: true }],
      pan_card_number: [{ value: '', disabled: true }],
      aadhar_number: [{ value: '', disabled: true }],
      father_name: [{ value: '', disabled: true }],
      mother_name: [{ value: '', disabled: true }],
      higher_education: [{ value: '', disabled: true }],
      dob: [{ value: '', disabled: true }],
      emergency_relation1: [{ value: '', disabled: true }],
      emergency_name1: [{ value: '', disabled: true }],
      emergency_phone1: [{ value: '', disabled: true }],
      emergency_relation2: [{ value: '', disabled: true }],
      emergency_name2: [{ value: '', disabled: true }],
      emergency_phone2: [{ value: '', disabled: true }],
      emergency_relation3: [{ value: '', disabled: true }],
      emergency_name3: [{ value: '', disabled: true }],
      emergency_phone3: [{ value: '', disabled: true }],
      official_email: [{ value: '', disabled: true }],
      official_email_password: [{ value: '', disabled: true }],
      official_skype: [{ value: '', disabled: true }],
      official_skype_password: [{ value: '', disabled: true }],
      personal_email: [{ value: '', disabled: true }],
      adress: [{ value: '', disabled: true }],
      adress2: [{ value: '', disabled: true }],
      bank_name: [{ value: '', disabled: true }],
      account_name: [{ value: '', disabled: true }],
      ifsc_code: [{ value: '', disabled: true }],
      uan_number: [{ value: '', disabled: true }],
      esi_number: [{ value: '', disabled: true }],

    });

 

  }


  AllEmployee() {
    this.dashboardService.allActiveEmployee().subscribe(response => {
      if (response.status_code == 200) {
        this.AllEmployeeCode = response.data;
       
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }

  employeeDetails(id: any) {

    let obj = {
      'id': id
    };
    this.dashboardService.SingleEmployeeDetails(obj).subscribe(response => {
      if (response.status_code == 200) {
      

        this.EmplooyeeTechnology = response.data.technology;
        this.employeeDetails = response.data.employer_details;
        if (response.data.employee.date_relieved) {
          this.relive_date = new Date(response.data.employee.date_relieved);
        } else {
          this.relive_date = '';
        }


        if (response.data.employee.dob) {
          this.dob = new Date(response.data.employee.dob);
        } else {
          this.dob = '';
        }



        this.updateEmployeeCode = this.formBuilder.group({
          employee_code: [response.data.employee.employee_code, [Validators.required,
          Validators.pattern("^[0-9]*$")]],
          user: [response.data.employee.ID, Validators.required],
          date: [new Date(response.data.employee.joining_date), Validators.required],
          designation: [response.data.employee.designation, Validators.required],
          updated_by: this.user,
          gender: [response.data.employee.gender],
          marital_status: [response.data.employee.marital_status],
          date_relieved: [this.relive_date],
          tel1: [response.data.employee.tel1],
          tel2: [response.data.employee.tel2],
          pan_card_number: [response.data.employee.pan_card_number],
          aadhar_number: [response.data.employee.aadhar_number],
          father_name: [response.data.employee.father_name],
          mother_name: [response.data.employee.mother_name],
          higher_education: [response.data.employee.higher_education],
          dob: [this.dob],
          emergency_relation1: [response.data.employee.emergency_relation1],
          emergency_name1: [response.data.employee.emergency_name1],
          emergency_phone1: [response.data.employee.emergency_phone1],
          emergency_relation2: [response.data.employee.emergency_relation2],
          emergency_name2: [response.data.employee.emergency_name2],
          emergency_phone2: [response.data.employee.emergency_phone2],
          emergency_relation3: [response.data.employee.emergency_relation3],
          emergency_name3: [response.data.employee.emergency_name3],
          emergency_phone3: [response.data.employee.emergency_phone3],
          official_email: [response.data.employee.official_email],
          official_email_password: [response.data.employee.official_email_password],
          official_skype: [response.data.employee.official_skype],
          official_skype_password: [response.data.employee.official_skype_password],
          personal_email: [response.data.employee.personal_email],
          adress: [response.data.employee.adress],
          adress2: [response.data.employee.adress2],
          bank_name: [response.data.employee.bank_name],
          account_name: [response.data.employee.account_name],
          ifsc_code: [response.data.employee.ifsc_code],
          uan_number: [response.data.employee.uan_number],
          esi_number: [response.data.employee.esi_number],

        });
      } else {

        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {


      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }

 
  AllTechnology() {

    this.dashboardService.AllTechnologies().subscribe(response => {
      if (response.status_code == 200) {
        this.AllTechnologies = response.data;

      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  } 
}
