import { NgModule } from '@angular/core';
import { Routes, RouterModule,CanActivate } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { TicketComponent } from './ticket/ticket.component';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { MyAttendanceComponent } from './my-attendance/my-attendance.component';
import { AttendanceLogComponent } from './attendance-log/attendance-log.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { RoleGuardService as RoleGuard } from './auth/role-guard.service';
import { WorkDaySettingComponent } from './work-day-setting/work-day-setting.component';
import { HolidaysComponent } from './holidays/holidays.component';
import { AttendanceSummaryComponent } from './attendance-summary/attendance-summary.component';
import { LeaveComponent } from './leave/leave.component';
import { EmployeeComponent } from './employee/employee.component';
import { TimeSettingComponent } from './time-setting/time-setting.component';
import { LeaveDetailsComponent } from './leave-details/leave-details.component';
import { EditEmployeeComponent } from './edit-employee/edit-employee.component';
import { TechnologyDashboardComponent } from './technology-dashboard/technology-dashboard.component';
import { HrSupportComponent } from './hr-support/hr-support.component';
import { EventCalendarComponent } from './event-calendar/event-calendar.component';
import { ProfileComponent } from './profile/profile.component';

import { WfhComponent } from "./wfh/wfh.component";
import { ChatgptComponent } from './chatgpt/chatgpt.component';
import { ChatgptDataComponent } from './chatgpt-data/chatgpt-data.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: "", component: LoginComponent, pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "dashboard", component: DashboardComponent },
  { path: "ticket", component: TicketComponent },
  { path: "ticket-detail", component: TicketDetailComponent },
  { path: "hr-support", component: HrSupportComponent },
  { path: "leave-detail", component: LeaveDetailsComponent },
  { path: "home", component: HomeComponent },
  // {path:'attendance',component:AttendanceComponent},
  {
    path: "event-calendar",
    component: EventCalendarComponent,
    // canActivate: [RoleGuard],
    // data: {
    //   expectedRole: 'Admin'
    // }
  },
  {
    path: "attendance",
    component: AttendanceComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: "Admin",
    },
  },
  {
    path: "work-day-setting",
    component: WorkDaySettingComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: "Admin",
    },
  },
  {
    path: "holidays",
    component: HolidaysComponent,
  },

  {
    path: "attendance-summary",
    component: AttendanceSummaryComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: "Admin",
    },
  },
  {
    path: "my-attendance-summary",
    component: AttendanceSummaryComponent,
  },
  {
    path: "leave",
    component: LeaveComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: "Admin",
    },
  },
  {
    path: "employee-code",
    component: EmployeeComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: "Admin",
    },
  },
  {
    path: "edit-employee",
    component: EditEmployeeComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: "Admin",
    },
  },
  {
    path: "time-setting",
    component: TimeSettingComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: "Admin",
    },
  },
  {
    path: "technology-dashboard",
    component: TechnologyDashboardComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: "Admin",
    },
  },
  {
    path: "attendance-summary",
    component: AttendanceLogComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: "Admin",
    },
  },
  {
    path: "attendance-log",
    component: AttendanceLogComponent,
  },
  { path: "my-leave", component: LeaveComponent },
  { path: "my-attendance", component: MyAttendanceComponent },

  { path: "profile", component: ProfileComponent },
  {
    path: "wfh",
    component: WfhComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: "Admin",
    },
  },
  { path: "my-wfh", component: WfhComponent },

  {
    path: "chatgpt",
    component: ChatgptComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: "Admin",
    },
  },
  { path: "chatgpt", component: ChatgptComponent },
  {
    path: "chatgptData",
    component: ChatgptDataComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: "Admin",
    },
  },
  { path: "chatgptData", component: ChatgptDataComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
