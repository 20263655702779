import { environment } from './../../environments/environment';
import { Component, ViewChild, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/** Services List */
import { DashboardService } from '../base/services/dashboard.service';
import { EventObject } from 'ngx-fullcalendar';
import { DatePipe } from '@angular/common';
import { ConfirmationDialogComponent } from '../components/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';


@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
})
export class DashboardComponent implements OnInit {
  @ViewChild("addticket") addticket: TemplateRef<any>;

  modalRef: BsModalRef;
  message: string;
  errorMessage: any;
  userSupport: any;
  FormValue: any;

  ticketForm: FormGroup;
  submitted = false;
  id: any;
  eventData = new Array<EventObject>();
  sortOrder: string = "desc";
  column: string = "id";

  typeArray: any = ["Live", "General", "Hardware", "Software", "Internet"];
  priorityArray: any = ["High", "Medium", "Low"];

  p: number = 1;
  type: String;
  color: string;

  show = false;

  constructor(
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) {
    this.id = localStorage.getItem("id");
  }
  ngOnInit() {
    this.getUserData();

    this.ticketForm = this.formBuilder.group({
      type: ["", Validators.required],
      priority: ["", Validators.required],
      query: ["", Validators.required],
      query_type: ["1", Validators.required],
    });
  }

  get f() {
    return this.ticketForm.controls;
  }

  getUserData() {
    let obj = {
      user_id: this.id,
    };

    this.dashboardService.GetAllUserQuery(obj).subscribe(
      (response) => {
        if (response.status_code == 200) {
          // this.getallleavelist();
          // this.getallwfhlist();
          this.userSupport = response.data.support;
        } else {
          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      },
      (error) => {
        if (error && error.status === 401) {
          this.router.navigate(["/login"]);
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      }
    );
  }

  AddTicket() {
    this.modalRef = this.modalService.show(this.addticket, {
      class: "logoutClass",
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.ticketForm.invalid) {
      return;
    } else {
      this.FormValue = this.ticketForm.value;
 
      let obj = {
        query: this.FormValue.query,
        type: this.FormValue.type,
        priority: this.FormValue.priority,
        assign_by: this.id,
        query_type: this.FormValue.query_type,
      };

      this.dashboardService.SaveQuery(obj).subscribe(
        (response) => {
          if (response.status_code == 200) {
            this.modalRef.hide();
            this.ngOnInit();
          } else {
            this.message = "No Data Found";
            setTimeout(() => {
              this.message = null;
            }, 2000);
          }
        },
        (error) => {
          if (error && error.status === 401) {
            this.modalRef.hide();
            this.router.navigate(["/login"]);
          } else {
            this.errorMessage = error;
            if (error.message != undefined) this.errorMessage = error.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 2000);
          }
        }
      );
    }
  }

  ticketPage(s_id: any) {
    this.router.navigate(["ticket-detail"], { queryParams: { id: s_id } });
  }

  // getallleavelist() {
  //   this.eventData = [];
  //   this.dashboardService
  //     .allLeave({
  //       sort_order: this.sortOrder,
  //       column: this.column,
  //     })
  //     .subscribe(
  //       (response) => {
  //         console.log(response);
  //         if (response.status_code == 200) {
  //           response.data.forEach((item) => {
  //             if (item.type == 1) {
  //               this.type = "";
  //             } else {
  //               this.type = " (Half Day)";
  //             }
  //             let newDate = new Date(item.to_date);
  //             let currentdate = new Date();

  //             if (item.status == 1) {
  //               console.log("Is Workfrom Home ", item.is_wfh);
  //               if(item.is_wfh != 1){
  //                 if (newDate.getTime() > currentdate.getTime()) {
  //                   this.color = "Orange";
  //                 }else{
  //                     this.color = "Red";
  //                 }
  //               }else {
  //                 this.color = "Aqua";
  //               }
  //             } else if (item.status == 2) {
  //               if(item.is_wfh==0){
  //               if (newDate.getTime() > currentdate.getTime()) {
  //                 this.color = "Green";
  //               } else {
  //                 this.color = "Grey";
  //               }
  //             }else{
  //               this.color = 'Aqua';
  //             }
  //             } else {
  //               this.color = "Blue";
  //             }

  //             let url = "127.0.0.1:8003/leave-detail?id=" + item.id;

  //             this.eventData.push({
  //               url: url,
  //               id: item.id,
  //               title: item.name + this.type,
  //               is_wfh:item.is_wfh,
  //               start: new Date(item.from_date),
  //               end: new Date(item.to_date),
  //               backgroundColor: this.color,
  //               textColor: "white",
  //             });
  //           });
  //           localStorage.setItem("leavedata", JSON.stringify(this.eventData));
  //         }
  //       },
  //       (error) => {
  //         if (error && error.status === 401) {
  //           console.log("Something went wrong");
  //         } else {
  //           this.errorMessage = error;
  //           if (error.message != undefined) this.errorMessage = error.message;
  //           setTimeout(() => {
  //             this.errorMessage = null;
  //           }, 2000);
  //         }
  //       }
  //     );
  //   this.dashboardService
  //     .allLeave({
  //       sort_order: this.sortOrder,
  //       column: this.column,
  //     })
  //     .subscribe(
  //       (response) => {
  //         console.log(response);
  //         if (response.status_code == 200) {
  //           response.data.forEach((item) => {
  //             if (item.type == 1) {
  //               this.type = "";
  //             } else {
  //               this.type = " (Half Day)";
  //             }
  //             let newDate = new Date(item.to_date);
  //             let currentdate = new Date();
  //             if (item.status == 1) {
  //               if(item.is_wfh == 0){
  //               if (newDate.getTime() > currentdate.getTime()) {
  //                 this.color = "Orange";
  //               } else {
  //                 this.color = "Red";
  //               }
                
  //             }else{
  //               this.color = "Aqua";
  //             }
  //             } else if (item.status == 2) {
  //               if(item.is_wfh==0){

                
  //               if (newDate.getTime() > currentdate.getTime()) {
  //                 this.color = "Green";
  //               } else {
  //                 this.color = "Grey";
  //               }
  //             }else{
  //               this.color = "Aqua";
  //             }
  //             } else {
  //               this.color = "Blue";
  //             }

  //             let url = "127.0.0.1:8003/leave-detail?id=" + item.id;

  //             this.eventData.push({
  //               url: url,
  //               id: item.id,
  //               title: item.name + this.type,
  //               start: new Date(item.from_date),
  //               is_wfh:item.is_wfh,
  //               end: new Date(item.to_date),
  //               backgroundColor: this.color,
  //               textColor: "white",
  //             });
  //           });
  //           console.log("doremon",this.eventData);
  //           localStorage.setItem("leavedata", JSON.stringify(this.eventData));
  //         }
  //       },
  //       (error) => {
  //         if (error && error.status === 401) {
  //           console.log("Sometging went wrong");
  //         } else {
  //           this.errorMessage = error;
  //           if (error.message != undefined) this.errorMessage = error.message;
  //           setTimeout(() => {
  //             this.errorMessage = null;
  //           }, 2000);
  //         }
  //       }
  //     );
  // }

  // getallwfhlist() {
  //   this.eventData = [];
  //   this.dashboardService
  //     .allWfh({
  //       sort_order: this.sortOrder,
  //       column: this.column,
  //     })
  //     .subscribe(
  //       (response) => {
  //         console.log(response);
  //         if (response.status_code == 200) {
  //           response.data.forEach((item) => {
  //             if (item.type == 1 && item.is_wfh==1) {
  //               this.type = "";
  //             } else {
  //               this.type = " (Half Day)";
  //             }
  //             let newDate = new Date(item.to_date);
  //             let currentdate = new Date();
  //             if (item.status == 1) {
  //               if (newDate.getTime() > currentdate.getTime()) {
  //                 this.color = "Aqua";
  //               } else {
  //                 this.color = "Red";
  //               }
  //             } 
  //             else if (item.status == 2) {
  //               if (newDate.getTime() > currentdate.getTime()) {
  //                 this.color = "Green";
  //               } else {
  //                 this.color = "Grey";
  //               }
  //             } else {
  //               this.color = "Blue";
  //             }

  //             let url = "127.0.0.1:8003/wfh-detail?id=" + item.id;

  //             this.eventData.push({
  //               url: url,
  //               id: item.id,
  //               title: item.name + this.type,
  //               start: new Date(item.from_date),
  //               end: new Date(item.to_date),
  //               backgroundColor: this.color,
  //               textColor: "white",
  //             });
  //           });
  //           console.log(this.eventData);
  //           localStorage.setItem("wfhdata", JSON.stringify(this.eventData));
  //         }
  //       },
  //       (error) => {
  //         if (error && error.status === 401) {
  //           console.log("Sometging went wrong");
  //         } else {
  //           this.errorMessage = error;
  //           if (error.message != undefined) this.errorMessage = error.message;
  //           setTimeout(() => {
  //             this.errorMessage = null;
  //           }, 2000);
  //         }
  //       }
  //     );
  //   this.dashboardService
  //     .allWfh({
  //       sort_order: this.sortOrder,
  //       column: this.column,
  //     })
  //     .subscribe(
  //       (response) => {
  //         console.log(response);
  //         if (response.status_code == 200) {
  //           response.data.forEach((item) => {
  //             if (item.type == 1) {
  //               this.type = "";
  //             } else {
  //               this.type = " (Half Day)";
  //             }
  //             let newDate = new Date(item.to_date);
  //             let currentdate = new Date();
  //             if (item.status == 1) {
  //               if (newDate.getTime() > currentdate.getTime()) {
  //                 this.color = "Orange";
  //               } else {
  //                 this.color = "Red";
  //               }
  //             } else if (item.status == 2) {
  //               if (newDate.getTime() > currentdate.getTime()) {
  //                 this.color = "Green";
  //               } else {
  //                 this.color = "Grey";
  //               }
  //             } else {
  //               this.color = "Blue";
  //             }

  //             let url = "127.0.0.1:8003/wfh-detail?id=" + item.id;

  //             this.eventData.push({
  //               url: url,
  //               id: item.id,
  //               title: item.name + this.type,
  //               start: new Date(item.from_date),
  //               end: new Date(item.to_date),
  //               backgroundColor: this.color,
  //               textColor: "white",
  //             });
  //           });
  //           console.log(this.eventData);
  //           localStorage.setItem("leavedata", JSON.stringify(this.eventData));
  //         }
  //       },
  //       (error) => {
  //         if (error && error.status === 401) {
  //           console.log("Sometging went wrong");
  //         } else {
  //           this.errorMessage = error;
  //           if (error.message != undefined) this.errorMessage = error.message;
  //           setTimeout(() => {
  //             this.errorMessage = null;
  //           }, 2000);
  //         }
  //       }
  //     );
  // }

  deleteTicket(support_id: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "100%",
      data: "Do you confirm the delete of this data?",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let obj = {
          support_id: support_id,
        };

        // console.log(obj);

        this.dashboardService.deleteSupport(obj).subscribe(
          (response) => {
            if (response.status_code == 200) {
              this.ngOnInit();
            } else {
              this.message = "No Data Found";
              setTimeout(() => {
                this.message = null;
              }, 2000);
            }
          },
          (error) => {
            if (error && error.status === 401) {
              this.router.navigate(["/login"]);
            } else {
              this.errorMessage = error;
              if (error.message != undefined) this.errorMessage = error.message;
              setTimeout(() => {
                this.errorMessage = null;
              }, 2000);
            }
          }
        );
      }
    });
  }
}
