import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DashboardService } from '../base/services/dashboard.service';

@Component({
  selector: 'app-change-log-details',
  templateUrl: './change-log-details.component.html',
  styleUrls: ['./change-log-details.component.css'],
})
export class ChangeLogDetailsComponent implements OnInit {
  @Input() changeLogData: any;
  // @Input() fetchChangeLogData: any;
  changeLogDetails: any[]; // Store change_log_data here
  changedataLogs: any[] = [];

  constructor(
    private http: HttpClient,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {
    // Call the API to get change_log_data
    this.fetchChangeLogData(this.changeLogData.id); // Assuming id is the change_log_id
  }

  fetchChangeLogData(changeLogId: number) {
    const options = {
      changeLogApi: `/change-log-data/${changeLogId}`,
    };
    this.dashboardService.fetchChangeLog(options).subscribe(
      (response) => {
        if (response.status_code === 200) {
          this.changedataLogs = response.data;
        } else {
        }
      },
      (error) => {}
    );
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }
}
