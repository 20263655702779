import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'customerEmailFilter'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    }
    // |  customerEmailFilter:search_val;let i = index pipe for html
    return value.filter((val) => {
      let rVal = (val.type.toLocaleLowerCase().includes(args)) || (val.member.name.toLocaleLowerCase().includes(args)) || (val.query.toLocaleLowerCase().includes(args));
      return rVal;
    })

  }

}