import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DashboardService } from '../base/services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { DatePipe } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../components/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.css'],
  providers: [DatePipe]
})
export class EditEmployeeComponent implements OnInit {

  @ViewChild('technology') employee: TemplateRef<any>;
  modaltechnology: BsModalRef;



  id: number;
  AllEmployeeCode: any;
  message: string;
  errorMessage: any;
  updateEmployeeCode: FormGroup;
  updateTechnology: FormGroup;
  updatePreviousEmployee: FormGroup;
  submitted = false;
  empsubmitted = false;
  techsubmitted = false;
  user: string;
  AllTechnologies: any;
  EmplooyeeTechnology: any;

  p: number = 1;
  AllTechnologiesList: any;
  relive_date: any = '';
  dob: any = '';
  employer_details: any;
  constructor(public dialog: MatDialog, private modalService: BsModalService, private datePipe: DatePipe, private flashMessage: FlashMessagesService, private formBuilder: FormBuilder, private dashboardService: DashboardService, private route: ActivatedRoute, private router: Router) {
    this.user = localStorage.getItem('id');
  }

  ngOnInit() {
    this.AllEmployee();
    this.AllTechnology();
    this.route.queryParams.subscribe(q_param => {
      if (q_param['id']) {
        this.id = +q_param['id'];
        this.employeeDetails(this.id);
      }
    });


    this.updateEmployeeCode = this.formBuilder.group({
      employee_code: ['', [Validators.required,
      Validators.pattern("^[0-9]*$")]],
      user: [{ value: '', disabled: true }, Validators.required],
      date: ['', Validators.required],
      designation: ['xasd', Validators.required],
      updated_by: this.user,
      gender: [''],
      marital_status: [''],
      date_relieved: [''],
      tel1: [''],
      tel2: [''],
      pan_card_number: [''],
      aadhar_number: [''],
      father_name: [''],
      mother_name: [''],
      higher_education: [''],
      dob: [''],
      emergency_relation1: [''],
      emergency_name1: [''],
      emergency_phone1: [''],
      emergency_relation2: [''],
      emergency_name2: [''],
      emergency_phone2: [''],
      emergency_relation3: [''],
      emergency_name3: [''],
      emergency_phone3: [''],
      official_email: [''],
      official_email_password: [''],
      official_skype: [''],
      official_skype_password: [''],
      personal_email: [''],
      adress: [''],
      adress2: [''],
      bank_name: [''],
      account_name: [''],
      ifsc_code: [''],
      uan_number: [''],
      esi_number: [''],

    });


    this.updateTechnology = this.formBuilder.group({

      user: [this.id, Validators.required],
      level: ['', Validators.required],
      technology: ['', Validators.required],
      created_by: this.user
    });

    this.updatePreviousEmployee = this.formBuilder.group({

      user: [this.id, Validators.required],
      organization: ['', Validators.required],
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      designation: ['', Validators.required],
      id: '',
    });

  }

  AllEmployee() {
    this.dashboardService.allActiveEmployee().subscribe(response => {
      if (response.status_code == 200) {
        this.AllEmployeeCode = response.data;
        
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }

  employeeDetails(id: any) {

    let obj = {
      'id': id
    };
    this.dashboardService.SingleEmployeeDetails(obj).subscribe(response => {
      if (response.status_code == 200) {
      

        this.EmplooyeeTechnology = response.data.technology;
        this.employer_details = response.data.employer_details;
        if (response.data.employee.date_relieved) {
          this.relive_date = new Date(response.data.employee.date_relieved);
        } else {
          this.relive_date = '';
        }


        if (response.data.employee.dob) {
          this.dob = new Date(response.data.employee.dob);
        } else {
          this.dob = '';
        }



        this.updateEmployeeCode = this.formBuilder.group({
          employee_code: [response.data.employee.employee_code, [Validators.required,
          Validators.pattern("^[0-9]*$")]],
          user: [response.data.employee.ID, Validators.required],
          date: [new Date(response.data.employee.joining_date), Validators.required],
          designation: [response.data.employee.designation, Validators.required],
          updated_by: this.user,
          gender: [response.data.employee.gender],
          marital_status: [response.data.employee.marital_status],
          date_relieved: [this.relive_date],
          tel1: [response.data.employee.tel1],
          tel2: [response.data.employee.tel2],
          pan_card_number: [response.data.employee.pan_card_number],
          aadhar_number: [response.data.employee.aadhar_number],
          father_name: [response.data.employee.father_name],
          mother_name: [response.data.employee.mother_name],
          higher_education: [response.data.employee.higher_education],
          dob: [this.dob],
          emergency_relation1: [response.data.employee.emergency_relation1],
          emergency_name1: [response.data.employee.emergency_name1],
          emergency_phone1: [response.data.employee.emergency_phone1],
          emergency_relation2: [response.data.employee.emergency_relation2],
          emergency_name2: [response.data.employee.emergency_name2],
          emergency_phone2: [response.data.employee.emergency_phone2],
          emergency_relation3: [response.data.employee.emergency_relation3],
          emergency_name3: [response.data.employee.emergency_name3],
          emergency_phone3: [response.data.employee.emergency_phone3],
          official_email: [response.data.employee.official_email],
          official_email_password: [response.data.employee.official_email_password],
          official_skype: [response.data.employee.official_skype],
          official_skype_password: [response.data.employee.official_skype_password],
          personal_email: [response.data.employee.personal_email],
          adress: [response.data.employee.adress],
          adress2: [response.data.employee.adress2],
          bank_name: [response.data.employee.bank_name],
          account_name: [response.data.employee.account_name],
          ifsc_code: [response.data.employee.ifsc_code],
          uan_number: [response.data.employee.uan_number],
          esi_number: [response.data.employee.esi_number],

        });
      } else {

        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {


      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }


  get f() { return this.updateEmployeeCode.controls; }

  updateEmployeeDetails(data) {

    this.submitted = true;
    if (this.updateEmployeeCode.invalid) {
      return;
    } else {

      let obj = {
        'date': this.datePipe.transform(data.value.date, 'yyyy-MM-dd'),
        'designation': data.value.designation,
        'employee_code': data.value.employee_code,
        'user': data.value.user,
        'updated_by': data.value.updated_by,
        'gender': data.value.gender,
        'marital_status': data.value.marital_status,
        'date_relieved': this.datePipe.transform(data.value.date_relieved, 'yyyy-MM-dd'),
        'tel1': data.value.tel1,
        'tel2': data.value.tel2,
        'pan_card_number': data.value.pan_card_number,
        'aadhar_number': data.value.aadhar_number,
        'father_name': data.value.father_name,
        'mother_name': data.value.mother_name,
        'higher_education': data.value.higher_education,
        'dob': this.datePipe.transform(data.value.dob, 'yyyy-MM-dd'),
        'emergency_relation1': data.value.emergency_relation1,
        'emergency_name1': data.value.emergency_name1,
        'emergency_phone1': data.value.emergency_phone1,
        'emergency_relation2': data.value.emergency_relation2,
        'emergency_name2': data.value.emergency_name2,
        'emergency_phone2': data.value.emergency_phone2,
        'emergency_relation3': data.value.emergency_relation3,
        'emergency_name3': data.value.emergency_name3,
        'emergency_phone3': data.value.emergency_phone3,
        'official_email': data.value.official_email,
        'official_email_password': data.value.official_email_password,
        'official_skype': data.value.official_skype,
        'official_skype_password': data.value.official_skype_password,
        'personal_email': data.value.personal_email,
        'adress': data.value.adress,
        'adress2': data.value.adress2,
        'bank_name': data.value.bank_name,
        'account_name': data.value.account_name,
        'ifsc_code': data.value.ifsc_code,
        'uan_number': data.value.uan_number,
        'esi_number': data.value.esi_number,


      };
      this.dashboardService.UpdateEmployeeCode(obj).subscribe(response => {
        if (response.status_code == 200) {

          this.flashMessage.show('Updated Successfully!', { cssClass: 'alert-success', timeout: 2000 });

          // this.employeeDetails(this.id);
        } else {
          this.flashMessage.show('Employee Code is already Assigned!', { cssClass: 'alert-success', timeout: 2000 });

          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      }, error => {

        this.flashMessage.show('Employee Code is already Assigned!', { cssClass: 'alert-success', timeout: 2000 });

        if (error && error.status === 401) {
          this.router.navigate(['/login'])
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      });
    }
  }


  AllTechnology() {

    this.dashboardService.AllTechnologies().subscribe(response => {
      if (response.status_code == 200) {
        this.AllTechnologies = response.data;

      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }


  get g() { return this.updateTechnology.controls; }


  updateEmployeeTechnology(techData) {
    this.techsubmitted = true;
    if (this.updateEmployeeCode.invalid) {
      return;
    } else {
      
      this.dashboardService.SaveEmployeeTechnology(techData.value).subscribe(response => {
        if (response.status_code == 200) {
          if (this.modaltechnology) {
            this.modaltechnology.hide();

          }
          

          
          this.ngOnInit();
        } else {

          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      }, error => {


        if (error && error.status === 401) {
          this.router.navigate(['/login'])
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      });




    }


  }


  editTechnology(technologyList: any) {

    this.AllTechnologiesList = technologyList;


    this.updateTechnology = this.formBuilder.group({

      user: [this.id, Validators.required],
      level: [technologyList.level, Validators.required],
      technology: [technologyList.technology_id, Validators.required],
      created_by: this.user
    });


    // this.modaltechnology = this.modalService.show(this.employee, {
    // });
  }


  deleteTechnology(t_id: any) {


    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '100%',
      data: "Do you confirm the delete of this data?"
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        let obj = {
          'id': t_id,

        };
 

        this.dashboardService.deleteTechnology(obj).subscribe(response => {
          if (response.status_code == 200) {

            this.ngOnInit();

          } else {
            this.message = "No Data Found";
            setTimeout(() => {
              this.message = null;
            }, 2000);
          }
        }, error => {
          if (error && error.status === 401) {

            this.router.navigate(['/login'])
          } else {
            this.errorMessage = error;
            if (error.message != undefined) this.errorMessage = error.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 2000);
          }
        });
      }
    });




  }


  get emp() { return this.updatePreviousEmployee.controls; }

  updatePreviousEmployeeData(empData) {

    this.empsubmitted = true;
    if (this.updatePreviousEmployee.invalid) {
      return;
    } else {

      let empDataobj = {
        'from_date': this.datePipe.transform(empData.value.from_date, 'yyyy-MM-dd'),
        'to_date': this.datePipe.transform(empData.value.to_date, 'yyyy-MM-dd'),
        'organization': empData.value.organization,
        'designation': empData.value.designation,
        'user_id': empData.value.user,
        'id': empData.value.id

      };

       
      // return false;

      this.dashboardService.SaveEmployerDetails(empDataobj).subscribe(response => {
        if (response.status_code == 200) {

       
          this.ngOnInit();
        } else {

          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      }, error => {


        if (error && error.status === 401) {
          this.router.navigate(['/login'])
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      });




    }


  }


  editEmployer(employerList: any) {

 
    this.updatePreviousEmployee = this.formBuilder.group({

      user: [employerList.user_id, Validators.required],
      organization: [employerList.organization, Validators.required],
      from_date: [new Date(employerList.from_date), Validators.required],
      to_date: [new Date(employerList.to_date), Validators.required],
      designation: [employerList.designation, Validators.required],
      id: employerList.id,
    });


    // this.modaltechnology = this.modalService.show(this.employee, {
    // });
  }


  deleteEmployer(e_id: any) {


    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '100%',
      data: "Do you confirm the delete of this data?"
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        let obj = {
          'id': e_id,

        };

       

        this.dashboardService.deleteEmployer(obj).subscribe(response => {
          if (response.status_code == 200) {

            this.ngOnInit();

          } else {
            this.message = "No Data Found";
            setTimeout(() => {
              this.message = null;
            }, 2000);
          }
        }, error => {
          if (error && error.status === 401) {

            this.router.navigate(['/login'])
          } else {
            this.errorMessage = error;
            if (error.message != undefined) this.errorMessage = error.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 2000);
          }
        });
      }
    });




  }

}
