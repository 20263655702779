import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../base/services/dashboard.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Router } from '@angular/router';

import { formatDate, DatePipe } from '@angular/common';

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ChangeLogDetailsComponent } from '../change-log-details/change-log-details.component'; // Import the new component

@Component({
  selector: 'app-attendance-log',
  templateUrl: './attendance-log.component.html',
  styleUrls: ['./attendance-log.component.css'],
  providers: [DatePipe],
})
export class AttendanceLogComponent implements OnInit {
  selectedChangeLog: any;
  modalRef: BsModalRef;
  modalAttendance: BsModalRef;
  modaleditAttendance: BsModalRef;
  modalEditAttendance: BsModalRef;

  attendanceLogs: any[] = [];
  employee_code: any[] = [];
  modalLogAttendance: any[] = [];
  EditDate: any;
  INtime: any;
  OutTime: any;
  emp_code: any;
  tableName: any;

  attendanceForm: FormGroup;
  attendanceFormAdd: FormGroup;
  attendanceEditSingle: FormGroup;
  submitted = false;
  id: any;
  message: string;
  errorMessage: any;
  modelDate = '';
  AllEmployeeCode: any;
  today = new Date();
  employee: any = '';
  month: any = '';
  attendanceList: any;
  attendanceFormEdit: FormGroup;
  checkedList: any = [];
  summary: any = [];
  selectedAll: any;

  masterSelected: boolean;
  checklist: any;
  searchStatus: any;

  sortOrder: string = 'desc';
  column: string = 'attendance_employee.id';
  sortClass: string = 'asc';

 
  constructor(
    private dashboardService: DashboardService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  ngOnInit() {
    this.employeeCode();
    let obj = {
      month: this.month,
      employee_code: this.employee,
      status: this.searchStatus,
      sort_order: this.sortOrder,
      column: this.column,
    };
    this.getAttendanceLog(obj);
  }

  openLogDetailsPopup(log: any) {
    this.selectedChangeLog = log;
    const initialState = {
      changeLogData: log,
    };
    this.modalService.show(ChangeLogDetailsComponent, { initialState });
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  sortData(order: string, column: string): void {
    if (order == 'desc') {
      this.sortOrder = 'asc';
      this.sortClass = 'asc';
    }
    if (order == 'asc') {
      this.sortOrder = 'desc';
      this.sortClass = 'desc';
    }
 
    this.column = column;

    let obj = {
      month: this.datePipe.transform(this.month, 'yyyy-MM-dd'),
      employee_code: this.employee,
      status: this.searchStatus,
      sort_order: this.sortOrder,
      column: this.column,
    };

    this.getAttendanceLog(obj);
  }

  getAttendanceLog(obj: any = null) {
    this.dashboardService.getAttendanceLog(obj).subscribe(
      (response) => {
        if (response.status_code == 200) {
          this.attendanceLogs = response.data;
        } else {
          this.message = 'No Data Found';
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      },
      (error) => {
        if (error && error.status === 401) {
          this.router.navigate(['/login']);
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      }
    );
  }

  employeeCode() {
    this.dashboardService.allEmployeeCode().subscribe(
      (response) => {
        if (response.status_code == 200) {
         
          (this.month = new Date()), (this.AllEmployeeCode = response.data);
        } else {
          this.message = 'No Data Found';
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      },
      (error) => {
        if (error && error.status === 401) {
          this.router.navigate(['/login']);
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      }
    );
  }

  searchFilter(status_val: any, type: any) {
  
    if (type == 'employee') {
      this.employee = status_val.target.value;
    } else if (type == 'month') {
      this.month = status_val;
    } else if (type == 'status') {
      this.searchStatus = status_val.target.value;
    }

    let obj = {
      month: this.datePipe.transform(this.month, 'yyyy-MM-dd'),
      employee_code: this.employee,
      status: this.searchStatus,
      sort_order: this.sortOrder,
      column: this.column,
    };

 

    this.checkedList = [];
    this.masterSelected = false;
    this.getAttendanceLog(obj);
  }
}
