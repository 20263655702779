import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { DashboardService } from '../base/services/dashboard.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../components/shared/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-attendance-summary',
  templateUrl: './attendance-summary.component.html',
  styleUrls: ['./attendance-summary.component.css'],
})
export class AttendanceSummaryComponent implements OnInit {
  @ViewChild('Summary') Summary: TemplateRef<any>;

  @ViewChild('EditSummary') EditSummary: TemplateRef<any>;

  modalEditSummary: BsModalRef;
  summaryEditForm: FormGroup;

  modalSummary: BsModalRef;
  summaryForm: FormGroup;
  id: string;
  submitted = false;

  message: string;
  errorMessage: any;
  modelDate = '';
  AllEmployeeCode: any;
  AllSummary: any;
  employee: any;
  month: any;
  employee_code: any;
  hideSummary: boolean = true;

  sortOrder: string = 'desc';
  column: string = 'attendance_summary.id';
  sortClass: string = 'desc';

  p: number = 1;

  constructor(
    private flashMessage: FlashMessagesService,
    private router: Router,
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) {
    this.id = localStorage.getItem('id');
    this.employee = localStorage.getItem('employee_code');
  }

  ngOnInit() {
 

    if (this.router.url == '/my-attendance-summary') {
      this.hideSummary = false;

      this.employeeCode();
      let obj = {
        month: this.month,
        employee_code: this.employee,
        sort_order: this.sortOrder,
        column: this.column,
      };

      setTimeout(() => {
        this.AllEmployeeSummary(obj);
      }, 2000);
    } else {
      this.employee = null;
      this.employeeCode();
      let obj = {
        month: null,
        employee_code: this.employee,
        sort_order: this.sortOrder,
        column: this.column,
      };

      setTimeout(() => {
        this.AllEmployeeSummary(obj);
      }, 10);
      this.summaryForm = this.formBuilder.group({
        date: [null, Validators.required],
        employee_code: ['', Validators.required],
        total_working_days: ['', Validators.required],
        present_days: ['', Validators.required],
        leave: ['', Validators.required],
        extra_working_days: ['', Validators.required],
        previous_adjustment: ['', Validators.required],
        consumption_leave: ['', Validators.required],
        late_leave: ['', Validators.required],
        holiday: ['', Validators.required],

        user_id: this.id,
      });
    }
  }

  sortData(order: string, column: string): void {
    if (order == 'desc') {
      this.sortOrder = 'asc';
      this.sortClass = 'asc';
    }
    if (order == 'asc') {
      this.sortOrder = 'desc';
      this.sortClass = 'desc';
    }
    
    this.column = column;

    let obj = {
      month: this.month,
      employee_code: this.employee,
      sort_order: this.sortOrder,
      column: this.column,
    };

    this.AllEmployeeSummary(obj);
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  employeeCode() {
    this.dashboardService.allEmployeeCode().subscribe(
      (response) => {
        if (response.status_code == 200) {
          this.AllEmployeeCode = response.data;
        } else {
          this.message = 'No Data Found';
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      },
      (error) => {
        if (error && error.status === 401) {
          this.router.navigate(['/login']);
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      }
    );
  }

  get f() {
    return this.summaryForm.controls;
  }

  addSummary() {
    this.modalSummary = this.modalService.show(this.Summary);
  }

  saveSummary() {
    this.submitted = true;
    if (this.summaryForm.invalid) {
      return;
    } else {
      

      let obj = this.summaryForm.value;
      this.dashboardService.saveAttendanceSummary(obj).subscribe(
        (response) => {
          if (response.status_code == 200) {
            // this.AllEmployeeCode = response.data;
            this.modalSummary.hide();
            this.summaryForm.reset();
            this.ngOnInit();
          } else {
            this.message = 'No Data Found';
            setTimeout(() => {
              this.message = null;
            }, 2000);
          }
        },
        (error) => {
          if (error && error.status === 401) {
            this.router.navigate(['/login']);
          } else {
            this.errorMessage = error;
            if (error.message != undefined) this.errorMessage = error.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 2000);
          }
        }
      );
    }
  }

  autoSummary() {
    let obj = {
      user_id: this.id,
    };
    this.dashboardService.autoSummary(obj).subscribe(
      (response) => {
       
        if (response.status_code == 200) {
          this.ngOnInit();
        } else {
         

          this.flashMessage.show(response.message, {
            cssClass: 'alert-success',
            timeout: 2000,
          });
          this.message = 'No Data Found';
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      },
      (error) => {
        this.flashMessage.show(
          'Some of the attendance are not verified! Or Previous Month Data is missing!',
          { cssClass: 'alert-success', timeout: 2000 }
        );
        if (error && error.status === 401) {
          this.router.navigate(['/login']);
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      }
    );
  }

  searchFilter(status_val: any, type: any) {
    if (type == 'employee') {
      this.employee = status_val.target.value;
    } else if (type == 'month') {
      this.month = status_val;
    }

    let obj = {
      month: this.month,
      employee_code: this.employee,
      sort_order: this.sortOrder,
      column: this.column,
    };

    this.AllEmployeeSummary(obj);
  }

  AllEmployeeSummary(obj: any = null) {
    

    this.dashboardService.AllEmployeeSummary(obj).subscribe(
      (response) => {
   
        if (response.status_code == 200) {
        
          this.AllSummary = response.data;
        } else {
          this.message = 'No Data Found';
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      },
      (error) => {
        if (error && error.status === 401) {
          this.router.navigate(['/login']);
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      }
    );
  }

  EditOneSummary(summary: any) {
    this.summaryEditForm = this.formBuilder.group({
      total_working_days: [summary.total_working_days, Validators.required],
      present_days: [summary.present_days, Validators.required],
      leave: [summary.leave_days, Validators.required],
      extra_working_days: [summary.extra_working_days, Validators.required],
      previous_adjustment: [summary.previous_adjustment, Validators.required],
      consumption_leave: [summary.consumption_leave, Validators.required],
      late_leave: [summary.late_leave, Validators.required],
      holiday: [summary.holiday, Validators.required],

      user_id: this.id,
      id: summary.id,
    });

    this.modalEditSummary = this.modalService.show(this.EditSummary, {
      // class: "logoutClass",
    });
  }

  get s() {
    return this.summaryEditForm.controls;
  }

  UpdateSummary() {
    this.submitted = true;
    if (this.summaryEditForm.invalid) {
      return;
    } else {
      
      let obj = this.summaryEditForm.value;

      this.dashboardService.UpdateSummary(obj).subscribe(
        (response) => {
         
          if (response.status_code == 200) {
            this.modalEditSummary.hide();
            this.ngOnInit();
          } else {
            this.message = 'No Data Found';
            setTimeout(() => {
              this.message = null;
            }, 2000);
          }
        },
        (error) => {
          if (error && error.status === 401) {
            this.router.navigate(['/login']);
          } else {
            this.errorMessage = error;
            if (error.message != undefined) this.errorMessage = error.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 2000);
          }
        }
      );
    }
  }

  ApproveSummary(summary: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '100%',
      data: 'Do you confirm the verification of this data?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let obj = {
          id: summary.id,
        };

        this.dashboardService.ApproveSummary(obj).subscribe(
          (response) => {
            if (response.status_code == 200) {
              this.ngOnInit();
            } else {
              this.message = 'No Data Found';
              setTimeout(() => {
                this.message = null;
              }, 2000);
            }
          },
          (error) => {
            if (error && error.status === 401) {
              this.router.navigate(['/login']);
            } else {
              this.errorMessage = error;
              if (error.message != undefined) this.errorMessage = error.message;
              setTimeout(() => {
                this.errorMessage = null;
              }, 2000);
            }
          }
        );
      }
    });
  }
}
