import { Component, OnInit, ViewChild } from '@angular/core';
import { FullCalendarOptions, EventObject, FullCalendarComponent } from 'ngx-fullcalendar';
import { DashboardService } from '../base/services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseLeave } from '../base/models/responseleave';



@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.css']
})
export class EventCalendarComponent implements OnInit {


  options: FullCalendarOptions;
  events = new Array<EventObject>();
  Currentdate: String
  sortOrder: string = 'desc';
  column: string = 'id';
  message: string;
  errorMessage: any;
  responsedata: Array<any> = [];
  list: any;

  eventData = new Array<EventObject>();


  constructor(private dashboardService: DashboardService, private router: Router) {
   
    let storedToken: any = localStorage.getItem('leavedata');
 

    this.events = JSON.parse(storedToken);
  
  
  }

  ngOnInit() {
    this.calendar();
  }

 

  calendar() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.Currentdate = yyyy + '-' + mm + '-' + "01";
   
    this.options = {
      defaultDate: this.Currentdate,
      editable: true,
      
     
    };
  }

}
