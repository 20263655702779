import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from '../../config/config';
/** Services List */
import { ServiceHttp } from './service-http.service';
/** Models List */
import { ResponseData } from '../models/response-data';

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private serviceHttp: ServiceHttp) {}

  GetAllUserQuery(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.userQuery, "POST", option);
  }

  SaveQuery(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.saveQuery, "POST", option);
  }

  dologin(option: any): Observable<ResponseData> {
    return this.serviceHttp.getLogin(Config.login, "POST", option);
  }

  GetAllQuery(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.allQuery, "POST", option);
  }

  closeremark(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.closeRemark, "POST", option);
  }

  deleteSupport(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.deleteSupport, "POST", option);
  }

  getDetails(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.supportDetail, "POST", option);
  }

  GetSearchQuery(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.searchQuery, "POST", option);
  }

  addAttendance(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.addAttendanceApi,
      "POST",
      option
    );
  }

  allEmployeeCode(): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.employeeAttendanceApi,
      "GET",
      ""
    );
  }

  fetchUserNames(): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.employeeAttendanceApi,
      "GET",
      ""
    );
  }

  saveEmployeeAttendance(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.saveEmployeeAttendanceApi,
      "POST",
      option
    );
  }

  allEmployeeAttendance(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.allEmployeeAttendanceApi,
      "POST",
      option
    );
  }

  deleteVerify(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.deleteVerifyApi, "POST", option);
  }

  editAttendance(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.editAttendanceApi,
      "POST",
      option
    );
  }

  saveSetting(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.saveSettingApi, "POST", option);
  }

  allSetting(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.allSettingApi, "POST", option);
  }

  deleteSetting(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.deleteSettingApi,
      "POST",
      option
    );
  }

  saveHolidays(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.saveHolidaysApi, "POST", option);
  }

  allHolidays(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.allHolidaysApi, "POST", option);
  }

  deleteHoliday(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.deleteHolidaysApi,
      "POST",
      option
    );
  }

  saveAttendanceSummary(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.attendanceSummaryApi,
      "POST",
      option
    );
  }

  autoSummary(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.autoSummaryApi, "POST", option);
  }

  AllEmployeeSummary(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.allSummaryApi, "POST", option);
  }

  UpdateSummary(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.updateSummaryApi,
      "POST",
      option
    );
  }

  // saveSearchInput(data: any): Observable<any> {
  //   return this.serviceHttp.getResponse(Config.getData, "GET", "");
  // }
  saveLeave(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.saveLeaveApi, "POST", option);
  }

  allLeave(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.allLeaveApi, "POST", option);
  }
  allLeaveWfh(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.allLeaveWfhApi, "POST", option);
  }
  allWfh(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.allWfhApi, "POST", option);
  }

  allChatgpt(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.addData, "POST", option);
  }

  fetchChatgptData(): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.getData, "GET", "");
  }

  approveReject(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.approveRejectApi,
      "POST",
      option
    );
  }

  allActiveEmployee(
    sort: any = null,
    order: any = "asc",
    column: any = "name"
  ): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.allactiveEmployeeApi +
        "?sort=" +
        sort +
        "&order=" +
        order +
        "&column=" +
        column,
      "GET",
      ""
    );
  }

  UpdateEmployeeCode(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.updateEmployeeCodeApi,
      "POST",
      option
    );
  }

  ApproveSummary(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.ApproveSummaryApi,
      "POST",
      option
    );
  }

  saveDateTimeSetting(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.savedateTimeSettingApi,
      "POST",
      option
    );
  }

  AllDateTime(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.AllDateTimeApi, "POST", option);
  }

  deleteDateTimeSetting(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.deletedatetimesettingApi,
      "POST",
      option
    );
  }

  RejectLeave(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.rejectLeaveApi, "POST", option);
  }

  getLeaveDetails(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.getLeaveApi, "POST", option);
  }

  SingleEmployeeDetails(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.employeeDetailsApi,
      "POST",
      option
    );
  }

  AllTechnologies(): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.alltechnologyApi, "GET", "");
  }

  SaveEmployeeTechnology(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.SaveEmployeeTechnologyApi,
      "POST",
      option
    );
  }
  deleteTechnology(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.deleteTechnologyApi,
      "POST",
      option
    );
  }

  HoildayArray(): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.holidaysArrayApi, "POST", []);
  }

  EmployeeTechnology(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.EmployeeTechnologyAPI,
      "POST",
      option
    );
  }

  projectDetails(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.projectDetailsAapi,
      "POST",
      option
    );
  }

  updateProjectDetails(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.updateProjectDetailsApi,
      "POST",
      option
    );
  }

  saveProjectDetails(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.saveProjectDetailsApi,
      "POST",
      option
    );
  }

  userProjectDetails(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.userProjectDetailsApi,
      "POST",
      option
    );
  }

  AllProject(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.allProjectApi, "POST", option);
  }

  SaveUserProject(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.saveUserProjectApi,
      "POST",
      option
    );
  }

  SaveEmployerDetails(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.SaveEmployerDetailsApi,
      "POST",
      option
    );
  }

  deleteEmployer(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.deleteEmployerApi,
      "POST",
      option
    );
  }

  deleteLeave(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(Config.deleteLeaveApi, "POST", option);
  }

  UpdateAccessCode(option: any): Observable<ResponseData> {
    return this.serviceHttp.getResponse(
      Config.UpdateAccessCodeApi,
      "POST",
      option
    );
  }

  getAttendanceLog(options: any): Observable<ResponseData> {
    // Assuming that 'Config.allEmployeeAttendanceApi' is the URL for getting attendance logs
    return this.serviceHttp.getResponse(
      Config.AttendanceLogApi,
      "POST",
      options
    );
  }

  fetchChangeLog(options: any): Observable<ResponseData> {
    // Assuming that 'Config.allEmployeeAttendanceApi' is the URL for getting attendance logs
    return this.serviceHttp.getResponse(options.changeLogApi, "GET", options);
  }
}
