import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
/** Services List */
import { DashboardService } from '../base/services/dashboard.service';
import { EventObject } from 'ngx-fullcalendar';
import { Config } from '../config/config';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  Currentdate: String;
  sortOrder: string = 'desc';
  column: string = 'id';
  message: string;
  errorMessage: any;
  responsedata: Array<any> = [];
  list: any;
  name: string;
  employee_code: string;
  randomQuote: string;
  holidaylist: Array<any> = [];
  eventlist: Array<any> = [];
  motivationalQuotes: Array<any> = [
    {
      quote: 'Code is like humor. When you have to explain it, it’s bad.',
      speaker: 'Cory House',
    },
    {
      quote: 'The only way to do great work is to love what you do.',
      speaker: 'Steve Jobs',
    },
    {
      quote: 'First, solve the problem. Then, write the code.',
      speaker: 'John Johnson',
    },
    {
      quote: 'If you think math is hard, try web development.',
      speaker: 'Trish Parr',
    },
    {
      quote: 'It’s not a bug. It’s an undocumented feature!',
      speaker: 'Anonymous',
    },
    {
      quote:
        'Code as if the person who ends up maintaining your code is a violent psychopath who knows where you live.',
      speaker: 'Martin Golding',
    },
    {
      quote:
        'Programming isn’t about what you know; it’s about what you can figure out.',
      speaker: 'Chris Pine',
    },
    {
      quote: 'The best error message is the one that never shows up.',
      speaker: 'Thomas Fuchs',
    },
    {
      quote: 'The best way to predict the future is to invent it.',
      speaker: 'Alan Kay',
    },
    {
      quote: "If at first, you don't succeed, call it version 1.0.",
      speaker: 'Unknown',
    },
    {
      quote:
        'Your most unhappy customers are your greatest source of learning.',
      speaker: 'Bill Gates',
    },
    {
      quote:
        'Do not worry about your difficulties in programming. You can also find bugs on the windshield.',
      speaker: 'Unknown',
    },
    {
      quote:
        'Don’t be afraid to ask dumb questions. They’re more easily handled than dumb mistakes.',
      speaker: 'Unknown',
    },
    {
      quote: 'Simplicity is the soul of efficiency.',
      speaker: 'Austin Freeman',
    },
    {
      quote:
        'Perfection is achieved not when there is nothing more to add, but rather when there is nothing more to take away.',
      speaker: 'Antoine de Saint-Exupéry',
    },
    {
      quote:
        'The art of debugging is figuring out what you really told your program to do.',
      speaker: 'Andrew Singer',
    },
    {
      quote:
        'The computer was born to solve problems that did not exist before.',
      speaker: 'Bill Gates',
    },
    {
      quote:
        "I'm not a great programmer; I'm just a good programmer with great habits.",
      speaker: 'Kent Beck',
    },
    {
      quote:
        'It’s not that I’m so smart; it’s just that I stay with problems longer.',
      speaker: 'Albert Einstein',
    },
    {
      quote:
        'Coding is like cooking. Most people can learn the basics, but it takes practice to make a masterpiece.',
      speaker: 'Unknown',
    },
    {
      quote: 'In software, the only numbers that matter are 0 and 1.',
      speaker: 'Unknown',
    },
    {
      quote: 'Code without tests is broken by design.',
      speaker: 'Jacob Kaplan-Moss',
    },
    {
      quote:
        'Every great developer you know got there by solving problems they were unqualified to solve until they actually did it.',
      speaker: 'Patrick McKenzie',
    },
    {
      quote:
        'The function of good software is to make the complex appear to be simple.',
      speaker: 'Grady Booch',
    },
    {
      quote:
        'The code you write makes you a programmer. The code you delete makes you a good one.',
      speaker: 'Mario Fusco',
    },
    {
      quote: 'Programming is not about typing, it’s about thinking.',
      speaker: 'Rich Hickey',
    },
    {
      quote:
        'The purpose of software engineering is to control complexity, not to create it.',
      speaker: 'Pamela Zave',
    },
    {
      quote: 'The best way to get a project done faster is to start sooner.',
      speaker: 'Jim Highsmith',
    },
    {
      quote: 'Good code is its own best documentation.',
      speaker: 'Steve McConnell',
    },
    {
      quote: 'The only thing that interferes with my learning is my education.',
      speaker: 'Albert Einstein',
    },
    {
      quote:
        'You might not think that programmers are artists, but programming is an extremely creative profession. It’s logic-based creativity.',
      speaker: 'John Romero',
    },
    {
      quote:
        "Code refactoring is like doing the dishes. It's never done; you just have to choose to stop at some point.",
      speaker: 'Unknown',
    },
    {
      quote: 'Programming is the art of doing one thing at a time.',
      speaker: 'Michael Feathers',
    },
    {
      quote: 'Don’t comment bad code. Rewrite it.',
      speaker: 'Brian W. Kernighan',
    },
    {
      quote: 'It’s not about ideas. It’s about making ideas happen.',
      speaker: 'Scott Belsky',
    },
    {
      quote: 'In order to be irreplaceable, one must always be different.',
      speaker: 'Coco Chanel',
    },
    {
      quote:
        'Success is stumbling from failure to failure with no loss of enthusiasm.',
      speaker: 'Winston S. Churchill',
    },
    {
      quote:
        "The only thing standing between you and your goal is the story you keep telling yourself as to why you can't achieve it.",
      speaker: 'Jordan Belfort',
    },
    {
      quote: "I have not failed. I've just found 10,000 ways that won't work.",
      speaker: 'Thomas A. Edison',
    },
    {
      quote:
        'The only limit to our realization of tomorrow will be our doubts of today.',
      speaker: 'Franklin D. Roosevelt',
    },
    {
      quote: 'It’s not whether you get knocked down, it’s whether you get up.',
      speaker: 'Vince Lombardi',
    },
    {
      quote:
        "You don't have to be great to start, but you have to start to be great.",
      speaker: 'Zig Ziglar',
    },
    {
      quote:
        'The only place where success comes before work is in the dictionary.',
      speaker: 'Vidal Sassoon',
    },
    {
      quote: "Your time is limited, don't waste it living someone else's life.",
      speaker: 'Steve Jobs',
    },
    {
      quote: 'Strive not to be a success, but rather to be of value.',
      speaker: 'Albert Einstein',
    },
    {
      quote:
        'The only person you are destined to become is the person you decide to be.',
      speaker: 'Ralph Waldo Emerson',
    },
    {
      quote: "You miss 100% of the shots you don't take.",
      speaker: 'Wayne Gretzky',
    },
    {
      quote: 'The only way to do great work is to love what you do.',
      speaker: 'Steve Jobs',
    },
    {
      quote: 'The only code that is bug-free is the one you never wrote.',
      speaker: 'Unknown',
    },
    {
      quote:
        'The only limit to our realization of tomorrow will be our doubts of today.',
      speaker: 'Franklin D. Roosevelt',
    },
    {
      quote: 'Code is poetry written by your future self.',
      speaker: 'Unknown',
    },
    {
      quote: 'Don`t watch the clock; do what it does. Keep going.',
      speaker: 'Sam Levenson',
    },
  ];

  type: String;
  color: string;
  eventData: Array<any> = [];

  endDate = new Date();
  startDate = new Date();

  constructor(
    private dashboardService: DashboardService,
    private router: Router,
  ) {
    this.name = localStorage.getItem('name');

    this.employee_code = localStorage.getItem('employee_code');
    this.randomQuote =
      this.motivationalQuotes[
        Math.floor(Math.random() * this.motivationalQuotes.length)
      ];
  }

  ngOnInit(): void {
    this.getallleavelist();
  }

  getallleavelist(): void {
    let obj = {
      sort_order: this.sortOrder,
      column: this.column,
      employee_code: this.employee_code,
    };
    this.eventData = [];

    this.dashboardService
      .allLeaveWfh({
        sort_order: this.sortOrder,
        column: this.column,
        employee_code: this.employee_code,
      })
      .subscribe((response) => {
        if (response.status_code == 200) {
          this.processData(response.data, 'leave');
        }
      });

    this.dashboardService.allHolidays(obj).subscribe((response) => {
      if (response.status_code === 200) {
        this.processData(response.data, 'holiday');
      }
    });

    // console.log('    this.eventlist', this.eventData);
  }

  processData(data: any[], typeValue: string): void {
    data.forEach((item) => {
      let url = '';
      let desc = '';

      if (typeValue === 'leave') {
        this.type = item.type === 1 ? '' : ' (Half Day)';
        this.type = item.is_wfh === 1 ? ' WFH' + this.type : this.type;

        let newDate = new Date(item.to_date);
        let currentdate = new Date();

        if (item.status == 1) {
          if (newDate.getTime() > currentdate.getTime()) {
            this.color = 'Orange';
          } else {
            this.color = 'Red';
          }
        } else if (item.status == 2) {
          if (newDate.getTime() > currentdate.getTime()) {
            this.color = 'Green';
          } else {
            this.color = 'Grey';
          }
        } else {
          this.color = 'Blue';
        }

        url = 'leave-detail?id=' + item.id;
        desc = item.name + this.type;

        this.endDate = new Date(item.to_date);
        this.startDate = new Date(item.from_date);
      } else {
        this.endDate = new Date(item.date);
        this.startDate = new Date(item.date);
        desc = item.description;
        this.color = 'Green';
      }

      this.eventData.push({
        url: url,
        id: item.id,
        title: desc,

        start: this.startDate,
        end: this.endDate,
        backgroundColor: this.color,
        textColor: 'white',
      });
    });
    localStorage.setItem('leavedata', JSON.stringify(this.eventData));

    // Your existing code to process leave/wfh data and push into eventData
  }
}
