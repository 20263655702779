import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ClockPickerConfig } from 'ng-clock-picker-lib';
import { DashboardService } from '../base/services/dashboard.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ConfirmationDialogComponent } from '../components/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
@Component({
  selector: 'app-time-setting',
  templateUrl: './time-setting.component.html',
  styleUrls: ['./time-setting.component.css'],
  providers: [DatePipe]
})
export class TimeSettingComponent implements OnInit {

  @ViewChild('timeSetting') timeSetting: TemplateRef<any>;
  modalTime :BsModalRef;
  timeFormAdd: FormGroup;
  id: string;
  today = new Date();
  config: ClockPickerConfig = {
    initialValue: '9:00',
    is24: true,
  };
  AllEmployeeCode: any;
  message: string;
  errorMessage: any;
  submitted = false;


  sortOrder: string = 'desc';
  column: string = 'date_time_setting.id';
  sortClass: string = 'asc';
  dateTimeList: any;
  p: number = 1;

  constructor(public dialog: MatDialog,private datePipe: DatePipe,private dashboardService: DashboardService, private router: Router,private formBuilder: FormBuilder,private modalService: BsModalService) { 
    this.id = localStorage.getItem('id');

  }

  ngOnInit() {

    this.employeeCode();

    let obj = {
      "sort_order": this.sortOrder,
      "column": this.column
    };


    this.AllDateTime(obj);

    this.timeFormAdd = this.formBuilder.group({
      start_date: [null, Validators.required],
      end_date:[null],
      arrival_time: ['', Validators.required],
      employee_code :[null],
      user_id: this.id
    });



  }

  employeeCode() {
    this.dashboardService.allEmployeeCode().subscribe(response => {
      if (response.status_code == 200) {
        this.AllEmployeeCode = response.data;
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {

        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }


  

  OpenSettingModal(){
    this.modalTime = this.modalService.show(this.timeSetting, {
    });
  }

  get g() { return this.timeFormAdd.controls; }


  saveTimeSetting(data) {

   
    
    this.submitted = true;
    if (this.timeFormAdd.invalid) {
      return;
    } else {

      let obj = {
        'start_date':this.datePipe.transform(data.value.start_date, 'yyyy-MM-dd'),
        'end_date':this.datePipe.transform(data.value.end_date, 'yyyy-MM-dd'),
        'employee_code' : data.value.employee_code,
        'arrival_time' : data.value.arrival_time,
        'user_id' : data.value.user_id

      };
     
      this.dashboardService.saveDateTimeSetting(obj).subscribe(response => {
        if (response.status_code == 200) {
          if (this.modalTime) {
            this.modalTime.hide();
          }

          this.timeFormAdd.reset();
          this.ngOnInit();

        } else {
          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      }, error => {
        if (error && error.status === 401) {

          this.router.navigate(['/login'])
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      });

    }
  }



  AllDateTime(obj: any = null) {

    this.dashboardService.AllDateTime(obj).subscribe(response => {
      if (response.status_code == 200) {
    

        this.dateTimeList = response.data;
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {

        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });
  }


  DeleteDateTimeSetting(data){



    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '100%',
      data: "Do you confirm the deletion of this data?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {


    let obj = {
      'id':data.id
    };
    this.dashboardService.deleteDateTimeSetting(obj).subscribe(response => {
      if (response.status_code == 200) {
 

        this.ngOnInit();
        // this.dateTimeList = response.data;
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {

        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

    }
    });

    
  }


}
