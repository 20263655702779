import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {NgxPaginationModule} from 'ngx-pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeftmenuComponent } from './layout/leftmenu/leftmenu.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FlashMessagesModule } from 'angular2-flash-messages';


/** Services List */
import { ServiceHttp } from './base/services/service-http.service';
import { CommonService } from './base/services/common.service';
import { LoginComponent } from './login/login.component';
import { TicketComponent } from './ticket/ticket.component';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { SearchPipe } from './base/pipe/serach.pipe';
import { AttendanceComponent } from './attendance/attendance.component';
import { MyAttendanceComponent } from './my-attendance/my-attendance.component';
import { AttendanceLogComponent } from './attendance-log/attendance-log.component';
import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { RoleGuardService } from './auth/role-guard.service';
import { AuthService } from './auth/auth.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { WorkDaySettingComponent } from './work-day-setting/work-day-setting.component';
import { HolidaysComponent } from './holidays/holidays.component';
import { AttendanceSummaryComponent } from './attendance-summary/attendance-summary.component';
import { LeaveComponent } from './leave/leave.component';
import { NgxDatetimeRangePickerModule } from 'ngx-datetime-range-picker';
import { MomentModule } from 'ngx-moment';
import { NgClockPickerLibModule } from 'ng-clock-picker-lib';
import { EmployeeComponent } from './employee/employee.component';
import { TimeSettingComponent } from './time-setting/time-setting.component';
import { LeaveDetailsComponent } from './leave-details/leave-details.component';
import { EditEmployeeComponent } from './edit-employee/edit-employee.component';
import { TechnologyDashboardComponent } from './technology-dashboard/technology-dashboard.component';
import { HrSupportComponent } from './hr-support/hr-support.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { EventCalendarComponent } from './event-calendar/event-calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxFullCalendarModule } from 'ngx-fullcalendar';
import { ProfileComponent } from './profile/profile.component';
import { ChangeLogDetailsComponent } from './change-log-details/change-log-details.component';
import { WfhComponent } from './wfh/wfh.component';
import { ChatgptComponent } from './chatgpt/chatgpt.component';
import { ChatgptDataComponent } from './chatgpt-data/chatgpt-data.component';
import { EditPopupComponent } from './edit-popup/edit-popup.component';
import { HomeComponent } from './home/home.component';


@NgModule({
  declarations: [
    AppComponent,
    LeftmenuComponent,
    DashboardComponent,
    LoginComponent,
    TicketComponent,
    TicketDetailComponent,
    SearchPipe,
    AttendanceComponent,
    MyAttendanceComponent,
    AttendanceLogComponent,
    ConfirmationDialogComponent,
    WorkDaySettingComponent,
    HolidaysComponent,
    AttendanceSummaryComponent,
    LeaveComponent,
    EmployeeComponent,
    TimeSettingComponent,
    LeaveDetailsComponent,
    EditEmployeeComponent,
    TechnologyDashboardComponent,
    HrSupportComponent,
    EventCalendarComponent,
    ProfileComponent,
    ChangeLogDetailsComponent,
    WfhComponent,
    ChatgptComponent,
    ChatgptDataComponent,
    EditPopupComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    LoadingBarHttpClientModule,
    BrowserModule,
    MatDialogModule,
    MatButtonModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
      },
    }),
    MomentModule,
    NgxDatetimeRangePickerModule.forRoot(),
    FlashMessagesModule.forRoot(),
    NgClockPickerLibModule,
    AccordionModule.forRoot(),
    SelectDropDownModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxFullCalendarModule,
  ],
  entryComponents: [ConfirmationDialogComponent, ChangeLogDetailsComponent,
    EditPopupComponent,
  ],
  providers: [
    ServiceHttp,
    CommonService,
    RoleGuardService,
    AuthService,
    AuthGuardService,
    JwtHelperService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function getToken() {
  return localStorage.getItem('token');
}