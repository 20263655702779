import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "../base/services/dashboard.service";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { CommonService } from "../base/services/common.service";
import { MatDialog } from "@angular/material";
import { ConfirmationDialogComponent } from "../components/shared/confirmation-dialog/confirmation-dialog.component";
import { FlashMessagesService } from "angular2-flash-messages";
import { DatePipe } from "@angular/common";
import { EditPopupComponent } from "../edit-popup/edit-popup.component";
import { ChangeDetectorRef } from "@angular/core";

@Component({
  selector: "app-chatgpt-data",
  templateUrl: "./chatgpt-data.component.html",
  styleUrls: ["./chatgpt-data.component.css"],
  providers: [DatePipe],
})
export class ChatgptDataComponent implements OnInit {
  @ViewChild("leave") chatgpt: TemplateRef<any>;
  @ViewChild("template") template: TemplateRef<any>;
  @ViewChild("EditAttendance") EditAttendance: TemplateRef<any>;
  user_message: any;
  created_at: any;
  sortColumn: string;
  yourDataService: any;
  attendanceLogs: any;
  filteredChatgptData: any[] = [];
  userNameFilter: string = "";
  createdFilter: string = "";
  sortData(order: string, field: string) {
    if (order == "desc") {
      this.sortOrder = "asc";
      this.sortClass = "asc";
    }
    if (order == "asc") {
      this.sortOrder = "desc";
      this.sortClass = "desc";
    }

    const dataToSort =
      this.employee === "" ? this.chatgptData : this.filteredChatgptData;

    if (
      field === "user_name" ||
      field === "user_message" ||
      field == "created_at"
    ) {
      this.sortOrder = order === "asc" ? "desc" : "asc";

      dataToSort.sort((a, b) => {
        const aValue = a[field].toLowerCase();
        const bValue = b[field].toLowerCase();

        return order === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
    }
  }

  reversedPagedChatgptData(): any[] {
    return this.pagedChatgptData.slice().reverse();
  }

  openEditPopup(item: any) {
    const initialState = {
      userDetails: item,
    };

    this.modalService.show(EditPopupComponent, {
      initialState,
    });
  }

  attendanceEditSingle: FormGroup;

  LeaveAdd: FormGroup;
  LeaveRejectForm: FormGroup;

  modalleave: BsModalRef;
  modalRef: BsModalRef;
  submitted = false;
  message: string;
  errorMessage: any;
  id: string;
  employee_code: string;
  hideSummary: boolean = true;

  sortOrder: string = "desc";
  column: string = "id";
  sortClass: string = "asc";
  leaveList: any;

  itemsPerPage: number = 1;

  p: number = 1;
  pagedChatgptData: any[] = [];
  chatgptData: any[] = [];
  renderData: any[] = [];
  selectedMonthYear: any;

  employee: any = null;
  month: any;
  AllEmployeeCode: any;
  showFullHalfDay: boolean;
  leave_id: any;
  leave_list: any;
  status: any;
  minDate: any = new Date();
  employeeFilter: string = "";
  monthFilter: string = "";
  maxDate: any;

  holiday: any = new Date();
  holidays: any = [];
  leave_date: any = [];
  inputValue: any;
  chatGPTResponse: any;
  user_name: any;
  searchStatus: any;
  constructor(
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private flashMessage: FlashMessagesService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) {
    this.id = localStorage.getItem("id");
    this.employee_code = localStorage.getItem("employee_code");
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 90);

    this.minDate.setDate(this.minDate.getDate() - 45);
  }
  ngOnInit() {
    this.fetchChatgptData();
    this.searchFilter({ target: { value: "" } }, "employee");
  }
  fetchChatgptData() {
    this.dashboardService.fetchChatgptData().subscribe(
      (response) => {
        if (response) {
          this.chatgptData = response.data;

          this.chatgptData.reverse();
          this.renderData = this.chatgptData;
        } else {
          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      },
      (error) => {
        if (error && error.status === 401) {
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      }
    );
  }

  fetchDataBasedOnSorting() {
    if (this.sortColumn === "user_message") {
      this.chatgptData.sort((a, b) => {
        const aValue = a.user_message.toLowerCase();
        const bValue = b.user_message.toLowerCase();

        if (this.sortOrder === "asc") {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      });
    } else {
      this.yourDataService
        .fetchDataWithSorting(this.sortColumn, this.sortOrder)
        .subscribe((data) => {
          this.chatgptData = data;
        });
    }
  }

  searchFilter(status_val: any, type: string) {
    this.filteredChatgptData = this.chatgptData;

    if (type === "employee") {
      this.employee = status_val.target.value;
    }

    if (type === "Created_At") {
      const selectedMonthYear = status_val;
      this.selectedMonthYear = selectedMonthYear;
    }

    if (this.employee) {
      this.filteredChatgptData = this.filteredChatgptData.filter(
        (item) => item.user_name === this.employee
      );
    }

    if (this.selectedMonthYear) {
      const dateObject = new Date(this.selectedMonthYear);
      const NowMonth = dateObject.getMonth();
      const NowYear = dateObject.getFullYear();

      this.filteredChatgptData = this.filteredChatgptData.filter((item) => {
        const itemDateObj = new Date(item.created_at);

        return (
          itemDateObj.getMonth() === NowMonth &&
          itemDateObj.getFullYear() === NowYear
        );
      });
    }
    this.renderData = this.filteredChatgptData;
  }

  getUniqueUserNames() {
    const uniqueUserNames = new Set<string>();

    this.chatgptData.forEach((item) => {
      uniqueUserNames.add(item.user_name);
    });

    return Array.from(uniqueUserNames);
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode("month");
  }
}
