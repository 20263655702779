import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DashboardService } from '../base/services/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
@Component({
  selector: 'app-technology-dashboard',
  templateUrl: './technology-dashboard.component.html',
  styleUrls: ['./technology-dashboard.component.css'],
  providers: [DatePipe]
})
export class TechnologyDashboardComponent implements OnInit {
  message: string;
  errorMessage: any;
  allTechnology: any;
  p: number = 1;
  date: any = new Date;
  AllEmployeeCode: any;
  employee: any = null;

  @ViewChild('project_details') project_details: TemplateRef<any>;
  modelProject: BsModalRef;

  @ViewChild('user_project_details') user_project_details: TemplateRef<any>;
  modelUserProject: BsModalRef;



  @ViewChild('add_tech') add_tech: TemplateRef<any>;
  modelAddTech: BsModalRef;



  projectData: any;

  timeArray: any = ['1', '2', '3', '4', '5', '6', '7', '8'];
  formproject: FormGroup;
  formuserproject: FormGroup;
  submitted: boolean = false;
  username: any;
  userprojectData: any;
  allProjectdata: any;
  loginuser: string;
  user_id: any;
  dropDownOption: any = [];
  config = {
    displayKey: "name",
    search: true,
    height: 'auto',
    placeholder: 'Select',
    customComparator: () => { },
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    searchOnKey: 'name',
    clearOnSelection: false 
  }
  techUser: any;
  userTech: any;
  level:any ="";

  Projectconfig =  {
    displayKey: "name",
    search: true,
    height: 'auto',
    placeholder: 'Select',
    customComparator: () => { },
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    searchOnKey: 'name',
    clearOnSelection: false 
  }


  Userconfig =  {
    displayKey: "name",
    search: true,
    height: 'auto',
    placeholder: 'Select',
    customComparator: () => { },
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    searchOnKey: 'name',
    clearOnSelection: false 
  }





  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private datePipe: DatePipe, private dashboardService: DashboardService, private router: Router) {
    this.loginuser = localStorage.getItem('id');
  }

  ngOnInit() {
    let obj = {
      'date': this.datePipe.transform(this.date, 'yyyy-MM-dd'),
      'user': this.employee
    };
    this.technology(obj);

    this.AllEmployee();

    this.formproject = this.formBuilder.group({
      user: ['', Validators.required],
      start: ['', Validators.required],
      end: null,
      time: ['', Validators.required],

    });


    this.formuserproject = this.formBuilder.group({
      project: ['', Validators.required],
      start: ['', Validators.required],
      end: null,
      time: ['', Validators.required],
    });


  }



  searchFilter(status_val: any, type: any) {
   
    if (type == 'date') {
      this.date = status_val;
    }
    if (type == 'employee') {
      this.employee = status_val.value.ID;
    }

    let obj = {

      'date': this.datePipe.transform(this.date, 'yyyy-MM-dd'),
      'user': this.employee
    };

    this.technology(obj);
  }




  technology(obj = null) {
    this.dashboardService.EmployeeTechnology(obj).subscribe(response => {
      if (response.status_code == 200) {
        this.allTechnology = response.data;
      
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }

  AllEmployee() {
    this.dashboardService.allActiveEmployee().subscribe(response => {
      if (response.status_code == 200) {
        this.AllEmployeeCode = response.data;


        
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }



  ProjectDetail(project_id: any) {
    this.formproject.reset();
    let obj = {
      'project_id': project_id,
      'date': this.datePipe.transform(this.date, 'yyyy-MM-dd'),
    };

    this.dashboardService.projectDetails(obj).subscribe(response => {
      if (response.status_code == 200) {
        this.projectData = response.data;
        this.projectData.assigned.forEach(element => {
          element.start_date = new Date(element.start_date);
          if (element.end_date) {
            element.end_date = new Date(element.end_date);
          }




        });

        this.modelProject = this.modalService.show(this.project_details, { class: 'largeModal',  backdrop: 'static' });

      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });


  }

  submitForm(index: number, id: number) {
 
    if (this.projectData && this.projectData.assigned != null && this.projectData.assigned.length > 0) {
      let obj = {
        'user': this.projectData.assigned[index].user.ID,
        'start': this.datePipe.transform(this.projectData.assigned[index].start_date, 'yyyy-MM-dd'),
        'end': this.datePipe.transform(this.projectData.assigned[index].end_date, 'yyyy-MM-dd'),
        'time': this.projectData.assigned[index].time,
        'ID': id,
        'date': this.datePipe.transform(this.date, 'yyyy-MM-dd'),

      }

 

      this.dashboardService.updateProjectDetails(obj).subscribe(response => {
        if (response.status_code == 200) {

        
        } else {
          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      }, error => {
        if (error && error.status === 401) {
          this.router.navigate(['/login'])
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      });




    }
  }


  get f() { return this.formproject.controls; }


  saveProjectAssigned(data, id) {


    this.submitted = true;
    if (this.formproject.invalid) {
      return;
    } else {

 
      let obj = {
        'start': this.datePipe.transform(data.value.start, 'yyyy-MM-dd'),
        'user': data.value.user.ID,
        'time': data.value.time,
        'end': this.datePipe.transform(data.value.end, 'yyyy-MM-dd'),
        'project': id,
        'date': this.datePipe.transform(this.date, 'yyyy-MM-dd'),

      };
      this.dashboardService.saveProjectDetails(obj).subscribe(response => {
        if (response.status_code == 200) {

          this.formproject.reset();
          this.projectData = response.data;
        
          this.projectData.assigned.forEach(element => {
            element.start_date = new Date(element.start_date);
            if (element.end) {
              element.end_date = new Date(element.end_date);
            }
          });


   
        } else {
          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      }, error => {
        if (error && error.status === 401) {
          this.router.navigate(['/login'])
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      });
    }
  }

  HideModel() {

    if (this.modelUserProject) {
      this.modelUserProject.hide();

    }

    if (this.modelProject) {
      this.modelProject.hide();

    }

    this.ngOnInit();
  }


  UserDetail(user_id, user_name) {

    this.username = user_name;
    this.user_id = user_id;
    let obj = {
      'user_id': user_id,
      'date': this.datePipe.transform(this.date, 'yyyy-MM-dd'),
    };

    this.dashboardService.userProjectDetails(obj).subscribe(response => {
      if (response.status_code == 200) {
        this.userprojectData = response.data;
        this.allProject();
        this.modelUserProject = this.modalService.show(this.user_project_details, {class: 'largeModal',  backdrop: 'static' });

      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }


  allProject() {

    let obj = {
      'user_id': this.loginuser
    };

    this.dashboardService.AllProject(obj).subscribe(response => {
      if (response.status_code == 200) {
        this.allProjectdata = response.data;


      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }


  get g() { return this.formuserproject.controls; }


  saveUserProject(data) {

    this.submitted = true;
    if (this.formuserproject.invalid) {
      return;
    } else {
   

      let obj = {
        'start': this.datePipe.transform(data.value.start, 'yyyy-MM-dd'),
        'user': this.user_id,
        'time': data.value.time,
        'end': this.datePipe.transform(data.value.end, 'yyyy-MM-dd'),
        'project': data.value.project.ID,
        'date': this.datePipe.transform(this.date, 'yyyy-MM-dd'),

      };


      this.dashboardService.SaveUserProject(obj).subscribe(response => {
        if (response.status_code == 200) {
         
          this.userprojectData = response.data;
        } else {
          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      }, error => {
        if (error && error.status === 401) {
          this.router.navigate(['/login'])
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      });

    }

  }


  addTechnology(user_id,tech_id,level){

      this.techUser = user_id;
      this.userTech = tech_id;
      this.level = level;
    this.modelAddTech = this.modalService.show(this.add_tech, {backdrop: 'static' });
  }


  UpdateTech(){

    let obj = {

      user: this.techUser,
      level:this.level,
      technology:this.userTech,
      created_by: this.loginuser
    };

 

    this.dashboardService.SaveEmployeeTechnology(obj).subscribe(response => {
      if (response.status_code == 200) {
        if( this.modelAddTech){
          this.modelAddTech.hide();

        }
        this.ngOnInit();
      } else {

        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {

      
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });



  }

}
