import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DashboardService } from '../base/services/dashboard.service';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../components/shared/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-work-day-setting',
  templateUrl: './work-day-setting.component.html',
  styleUrls: ['./work-day-setting.component.css']
})
export class WorkDaySettingComponent implements OnInit {

  @ViewChild('working') working: TemplateRef<any>;



  modalWorking: BsModalRef;

  submitted = false;

  categories :  Array<any> = [];

  type: any;
  date: any;
  showClass: boolean = true;

  days: any = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  weekDays: any = [
    ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
    ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
    ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
    ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
    ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
    ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
    ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5']
  ];
  show: boolean = false;
  errortype: string;
  errordate: string;
  errorcategories: any;
  message: string;
  errorMessage: any;
  arr: Array<any> = [];
  id: string;
  setting:any;

  sortOrder: string = 'asc';
  column:string = 'id';
  sortClass: string = 'asc';

  p: number = 1;



  constructor( private router: Router,public dialog: MatDialog,private dashboardService: DashboardService, private modalService: BsModalService, private formBuilder: FormBuilder) { 
    // this.categories[0] = new Array();
    this.id = localStorage.getItem('id');
  }

  ngOnInit() {

    let obj = {
      "sort_order": this.sortOrder,
      "column": this.column
    };
this.AllSetting(obj);

  }

  sortData(order: string,column:string): void {
    if (order == 'desc'){
      this.sortOrder = 'asc';
      this.sortClass = 'asc';
    }
    if (order == 'asc'){
      this.sortOrder = 'desc';
      this.sortClass = 'desc';
    }
   
    this.column = column;

    let obj = {
      "sort_order": this.sortOrder,
      "column": this.column
    };

    this.AllSetting(obj);
  }




  openSetting() {
   
    this.modalWorking = this.modalService.show(this.working);
  }


  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }




  handleChange(event: any) {
    
    if (event.target.value == 1) {
      this.show = true;
    } else {
      this.show = false;
    }
  }

  FieldsChange(event:any, i:number, j:number,week:string,days:string) {
  

    let ind: number =  this.categories.findIndex(p => p.key == event.target.value)
    if (ind == -1) {
      this.categories.push({"key":event.target.value, "value": event.currentTarget.checked});
    } else {
      this.categories = this.categories.filter((val) => val.key != event.target.value);
    }
    
  }


  saveWorkingDay() {

    if (this.type == 1) {
      if (this.date) {
        this.errordate = null;
      } else {
        this.errordate = "Month is Required!";
        return false;
      }
      this.errortype = null;
    } else if (this.type == 2) {
      this.errortype = null;
      this.errordate = null;
      this.date = null;
    } else {
      this.errortype = "Type is Required!";
      return false;
    }

    if(this.categories.length < 1){
      
      this.errorcategories = "Days is Required";
      return false;

    }else{
      this.errorcategories  =null;
    }

    let obj = {
      "type": this.type,
      "date": this.date,
      "days": this.categories,
      'user_id':this.id
    }
 
    this.dashboardService.saveSetting(obj).subscribe(response => {
      if (response.status_code == 200) {
        this.date = null;
        this.type = null;
        this.categories = [];

        this.modalWorking.hide();
        this.ngOnInit();


      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }



  AllSetting(obj){

    this.dashboardService.allSetting(obj).subscribe(response => {
      if (response.status_code == 200) {
      
        this.setting = response.data;
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }

  deleteSetting(setting_id:number){


    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '100%',
      data: "Do you confirm the deletion of this data?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {

        let obj = {
            'setting_id':setting_id
        };

        

        this.dashboardService.deleteSetting(obj).subscribe(response => {
          if (response.status_code == 200) {

            this.ngOnInit();

          } else {
            this.message = "No Data Found";
            setTimeout(() => {
              this.message = null;
            }, 2000);
          }
        }, error => {
          if (error && error.status === 401) {
            this.router.navigate(['/login'])
          } else {
            this.errorMessage = error;
            if (error.message != undefined) this.errorMessage = error.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 2000);
          }
        });
      }
    });

  
  }

}
