import { Component, Input, ChangeDetectorRef } from "@angular/core";

@Component({
  selector: "app-edit-popup",
  templateUrl: "./edit-popup.component.html",

  styleUrls: ["./edit-popup.component.css"],
})
export class EditPopupComponent {
  @Input() userDetails: any;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges() {
    this.cdr.detectChanges();
  }
}
