export class Config {
  // public static BASE_URL = '';
  //  public static API_URL = 'http://192.168.1.192/cf-sys-attendance/public';
  // public static API_URL = "http://127.0.0.1:8000/api";

  public static API_URL = "http://pm.codefire.in/pms_laravel_new/public/api";

  // public static API_URL = "http://127.0.0.1:3001";

  /** URL List */
  public static userQuery = "/user-query";
  public static saveQuery = "/save-query";
  public static login = "/login";
  public static allQuery = "/all-query";
  public static closeRemark = "/close-remark";
  public static deleteSupport = "/delete-support";
  public static supportDetail = "/support-detail";
  public static searchQuery = "/search-query";
  public static addAttendanceApi = "/add-attendance";
  public static employeeAttendanceApi = "/employee-code";
  public static saveEmployeeAttendanceApi = "/save-employee-attendance";
  public static allEmployeeAttendanceApi = "/all-employee-attendance";
  public static AttendanceLogApi = "/attendance-log";
  // public static ChangeLogApi = "/change-log-data/1";
  public static deleteVerifyApi = "/delete-verify";
  public static editAttendanceApi = "/edit-attendance";
  public static saveSettingApi = "/save-days-setting";
  public static allSettingApi = "/all-setting";
  public static deleteSettingApi = "/delete-setting";
  public static saveHolidaysApi = "/save-holidays";
  public static allHolidaysApi = "/all-holidays";
  public static deleteHolidaysApi = "/delete-holidays";
  public static attendanceSummaryApi = "/attendance-summary";
  public static autoSummaryApi = "/auto-summary";
  public static allSummaryApi = "/all-employee-summary";
  public static updateSummaryApi = "/update-summary";
  public static saveLeaveApi = "/save-leave";
  public static allLeaveApi = "/all-leave";
  public static allLeaveWfhApi = "/all-leave-wfh";
  public static allWfhApi = "/all-wfh";
  // public static allChatgptApi = "/chatgpt";
  public static addData = "/chatgpt";
  public static getData = "/chatgptData";
  public static approveRejectApi = "/approve-reject-leave";
  public static allactiveEmployeeApi = "/all-active-employee";
  public static updateEmployeeCodeApi = "/update-employee-code";
  public static ApproveSummaryApi = "/approve-summary";
  public static savedateTimeSettingApi = "/save-date-time-setting";
  public static AllDateTimeApi = "/all-date-time-setting";
  public static deletedatetimesettingApi = "/delete-date-time-setting";
  public static rejectLeaveApi = "/reject-leave";
  public static getLeaveApi = "/leave-details";
  public static employeeDetailsApi = "/employee-details";
  public static alltechnologyApi = "/all-technology";
  public static SaveEmployeeTechnologyApi = "/save-employee-technology";
  public static deleteTechnologyApi = "/delete-technology";
  public static holidaysArrayApi = "/holiday-array";
  public static EmployeeTechnologyAPI = "/technology-dashboard";
  public static projectDetailsAapi = "/project-details";
  public static updateProjectDetailsApi = "/update-project-details";
  public static saveProjectDetailsApi = "/save-project-details";
  public static userProjectDetailsApi = "/user-project-details";
  public static allProjectApi = "/all-project";
  public static saveUserProjectApi = "/save-user-project";
  public static SaveEmployerDetailsApi = "/save-employer-details";
  public static deleteEmployerApi = "/delete-employer";
  public static deleteLeaveApi = "/delete-leave";
  public static UpdateAccessCodeApi = "/update-access-code";
}
