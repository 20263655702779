import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class CommonService {

    

    isToken = new BehaviorSubject<boolean>(true);
    $castToken = this.isToken.asObservable();

    isRole = new BehaviorSubject<string>('');
    $castRole = this.isRole.asObservable();


    public getToken(bool: boolean) {
        this.isToken.next(bool);
    }
    public updateRole(role: string) {
        return this.isRole.next(role);
    }
}