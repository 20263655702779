import { Injectable } from '@angular/core';
import { Config } from '../../config/config';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from './common.service';
import { error } from '@angular/compiler/src/util';

@Injectable()
export class ServiceHttp {

	url: string;
	token: any;

	constructor(private _http: HttpClient, private router: Router, private commonService: CommonService) {
	}
	getResponse(url: String, method: String, data = null): any {

		this.url = Config.API_URL + url;
		if (method == 'GET') {
			return this._http.get(this.url, { headers: this.setHeader() }).pipe(map(data =>
				this.extractData(data)
				
			));
		} else if (method == 'POST') {
			return this._http.post(this.url, data, { headers: this.setHeader() }).pipe(map(data =>
				this.extractData(data)
			));
		}
	}

	getLogin(url: String, method: String, data = null): any {

		this.url = Config.API_URL + url;
		if (method == 'GET') {
			return this._http.get(this.url, { headers: this.setHeader() }).pipe(map(data =>
				this.extractData(data)
			));
		} else if (method == 'POST') {
			return this._http.post(this.url, data, { headers: this.setHeaderlogin() }).pipe(map(data =>
				this.extractData(data)
			));
		}
	}


	private extractData(body: any): any {

		return body;
	}

	private setHeader() {
		this.token = localStorage.getItem('token');
		const headers = new HttpHeaders().append('authToken', this.token).append('Authorization', 'Bearer ' + this.token).append('Content-Type', 'application/json').append('type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
		return headers;
	}

	private setHeaderlogin() {
		const headers = new HttpHeaders().append('Content-Type', 'application/json').append('type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
		return headers;

	}

}
