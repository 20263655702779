import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../base/services/dashboard.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.css']
})
export class TicketDetailComponent implements OnInit {
  id: number;
  errorMessage: any;
  details:any;
  logs: any;

  constructor(private _location: Location,private dashboardService: DashboardService,private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(q_param => {
      if (q_param['id']) {
        this.id = +q_param['id'];
        this.getTicketDetail(this.id);
      }
    });
  }

  getTicketDetail(id:number) {
    let obj = {
      "support_id":id
    }
    this.dashboardService.getDetails(obj).subscribe(response => {
      if(response.status_code ==200){
    
          
        this.details  =response.data.data;
        this.logs =response.data.log;
    }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });
  }

  backClicked() {
    this._location.back();
  }

}
