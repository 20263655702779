import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { DashboardService } from '../base/services/dashboard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

  AllEmployeeCode: any;
  id: string;
  message: string;
  errorMessage: any;

  addEmployeeCode: FormGroup;
  AccessCode: FormGroup;
  p: number = 1;
  submitted = false;
  access_submitted = false;
  theCheckbox: boolean = false;


  @ViewChild('employee') employee: TemplateRef<any>;
  modalEmployee: BsModalRef;

  sortOrder: string = 'asc';
  column: string = 'user.name';
  sortClass: string = 'asc';


  @ViewChild('employeeAccessCode') employeeAccessCode: TemplateRef<any>;
  modalEmployeeAccess: BsModalRef;

  employeeData: any;


  constructor(private modalService: BsModalService, private flashMessage: FlashMessagesService, private formBuilder: FormBuilder, private dashboardService: DashboardService, private router: Router, private route: ActivatedRoute) {
    this.id = localStorage.getItem('id');

  }
  ngOnInit() {
    this.AllEmployee();
    this.addEmployeeCode = this.formBuilder.group({
      employee_code: ['', [Validators.required,
      Validators.pattern("^[0-9]*$")]],
      user: ['', Validators.required],
      date: ['', Validators.required],
      designation: ['', Validators.required],
      updated_by: this.id
    });





  }



  get f() { return this.addEmployeeCode.controls; }


  AllEmployee(sort: any = null, order: string = this.sortOrder, column: string = this.column) {
    this.dashboardService.allActiveEmployee(sort, order, column).subscribe(response => {
      if (response.status_code == 200) {
        this.AllEmployeeCode = response.data;
    
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }

  updateEmployeeCode() {

    this.submitted = true;
    if (this.addEmployeeCode.invalid) {
      return;
    } else {
      let obj = this.addEmployeeCode.value
      this.dashboardService.UpdateEmployeeCode(obj).subscribe(response => {
        if (response.status_code == 200) {
          this.modalEmployee.hide();
          this.ngOnInit();

        } else {
          this.flashMessage.show('Employee Code is already Assigned!', { cssClass: 'alert-success', timeout: 2000 });

          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      }, error => {

        this.flashMessage.show('Employee Code is already Assigned!', { cssClass: 'alert-success', timeout: 2000 });

        if (error && error.status === 401) {
          this.router.navigate(['/login'])
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      });


    }
  }


  AddEmployee() {
    this.modalEmployee = this.modalService.show(this.employee, {
    });
  }


  editPage(d_id: any) {
 
    this.router.navigate(['edit-employee'], { queryParams: { id: d_id } });
  }

  ShowAll(event) {
 
    if (this.theCheckbox) {
      this.AllEmployee(1, this.sortOrder, this.column);
    } else {
      this.AllEmployee(null, this.sortOrder, this.column);
    }


  }



  UpdateAccessCode(employeeData) {
    this.employeeData = employeeData;


    this.AccessCode = this.formBuilder.group({

      name: [employeeData.name],
      employee_code: [employeeData.employee_code],

      access_code: [employeeData.access_code, [Validators.required]],

    });

    this.modalEmployeeAccess = this.modalService.show(this.employeeAccessCode, {
    });
  }


  get access() { return this.AccessCode.controls; }



  updateAccessCode(AccessCode) {

    this.access_submitted = true;
    if (this.AccessCode.invalid) {
      return;
    } else {
    
      let obj = this.AccessCode.value;
      
      this.dashboardService.UpdateAccessCode(obj).subscribe(response => {
        if (response.status_code == 200) {
          this.modalEmployeeAccess.hide();
          this.ngOnInit();

        } else {
          this.flashMessage.show('Access code does not exists!', { cssClass: 'alert-success', timeout: 2000 });

          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      }, error => {

        this.flashMessage.show('Access code does not exists!', { cssClass: 'alert-success', timeout: 2000 });

        if (error && error.status === 401) {
          this.router.navigate(['/login'])
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      });


    }
  }



  sortData(order: string, column: string): void {
    if (order == 'desc') {
      this.sortOrder = 'asc';
      this.sortClass = 'asc';
    }
    if (order == 'asc') {
      this.sortOrder = 'desc';
      this.sortClass = 'desc';
    }
    
    this.column = column;

    if (this.theCheckbox) {
      this.AllEmployee(1, this.sortOrder, this.column);
    } else {
      this.AllEmployee(null, this.sortOrder, this.column);
    }



  }



}
