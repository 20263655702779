import { Component } from '@angular/core';
import { CommonService } from './base/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  token:any;
  title = 'Cf-support';
  menu:boolean;
  role: string;

   constructor(private route: ActivatedRoute, private router: Router,private commonService: CommonService) {


    this.role = localStorage.getItem('role');
    this.commonService.updateRole(this.role);

    this.commonService.$castToken.subscribe(bool => {
        this.menu = bool;
    });

   

    }

  
}
