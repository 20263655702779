import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../base/services/dashboard.service';
import { CommonService } from '../base/services/common.service';
import { EventObject } from 'ngx-fullcalendar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  loginForm: FormGroup;
  submitted = false;

  message: string;
  errorMessage: any;
  loginValue: any;
  eventData = new Array<EventObject>();
  sortOrder: string = 'desc';
  column: string = 'id';

  
  constructor(private commonService: CommonService,private dashboardService: DashboardService,private formBuilder: FormBuilder,private router: Router, private route: ActivatedRoute) { 
    this.commonService.getToken(false);
  }

  ngOnInit() {
  
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
  });
  }

  get f() { return this.loginForm.controls; }



  onSubmit() {
    this.submitted = true;
      if (this.loginForm.invalid) {
           return;
      }else{
          this.loginValue= this.loginForm.value;
      
           this.dashboardService.dologin(this.loginValue).subscribe(response => {
            if (response.status_code == 200) {
              localStorage.setItem('name', response.data.name);
              localStorage.setItem('email', response.data.email);
              localStorage.setItem('token', response.data.token);
              localStorage.setItem('id', response.data.ID);
              localStorage.setItem('role', response.data.role);
              localStorage.setItem('employee_code', response.data.employee_code);
              localStorage.setItem('designation', response.data.designation);
              this.commonService.getToken(true);
              this.commonService.updateRole(response.data.role);
              // this.getallleavelist();
              this.router.navigate(['/home'])
            } else if(response.status_code == 422){

              this.errorMessage = "Username or password is incorrect!"
            }else {
              this.message = "No Data Found";
              setTimeout(() => {
                this.message = null;
              }, 2000);
            }
          }, error => {

            if (error && error.status === 422) {
              this.errorMessage = "Username or password is incorrect!";
              if (error.message != undefined) this.errorMessage = "Username or password is incorrect!";
              setTimeout(() => {
                this.errorMessage = null;
              }, 2000);
            }else{
              this.errorMessage = error;
              if (error.message != undefined) this.errorMessage = error.message;
              setTimeout(() => {
                this.errorMessage = null;
              }, 2000);
            
            }
          });

      }

}

getallleavelist() {
  this.eventData = [];
  this.dashboardService.allLeave({
    "sort_order": this.sortOrder,
    "column": this.column
  }).subscribe(response => {
    console.log(response);
    if (response.status_code == 200) {
      response.data.forEach((item) => {
        this.eventData.push(
          { id: item.id, title: item.name, start: item.from_date, end: item.to_date, backgroundColor:'red' }
        );
      });
      console.log(this.eventData);
      localStorage.setItem("leavedata",JSON.stringify(this.eventData));


      // this.events = Object.assign([], this.eventData);
      // console.log(this.events);
      // this.calendar();
    }
  }, error => {

    if (error && error.status === 401) {
      console.log("Sometging went wrong");
    } else {
      this.errorMessage = error;
      if (error.message != undefined) this.errorMessage = error.message;
      setTimeout(() => {
        this.errorMessage = null;
      }, 2000);
    }
  });
}



}
