import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { DashboardService } from '../base/services/dashboard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../base/services/common.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../components/shared/confirmation-dialog/confirmation-dialog.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-leave-details',
  templateUrl: './leave-details.component.html',
  styleUrls: ['./leave-details.component.css']
})
export class LeaveDetailsComponent implements OnInit {


  @ViewChild('template') template: TemplateRef<any>;

  LeaveRejectForm: FormGroup;
  id: number;
  errorMessage: any;
  details: any;
  logs: any;
  roleArray:any = ['Admin','System Admin'];
  hideSummary:boolean =false;
  message: string;
  leave_id: any;
  modalRef: BsModalRef;
  submitted: boolean = false;
  leave_list: any;
  constructor(private modalService: BsModalService,private formBuilder: FormBuilder, public dialog: MatDialog,private commonService: CommonService,private _location: Location, private dashboardService: DashboardService, private route: ActivatedRoute, private router: Router) { 
    this.commonService.$castRole.subscribe(val => {
      
      setTimeout(() => {
       
        if(this.roleArray.includes(val)){
         
          if(val =='Admin'){

            this.hideSummary = true;
          }
        } 
      });

    });

  }

  ngOnInit() {

    this.route.queryParams.subscribe(q_param => {
      if (q_param['id']) {
        this.id = +q_param['id'];
          this.getTicketDetail(this.id);
      }
    });


  }


  getTicketDetail(id:number) {
    let obj = {
      "leave_id":id
    }
    this.dashboardService.getLeaveDetails(obj).subscribe(response => {
      if(response.status_code ==200){
          
          
        this.details  =response.data.data;
        this.logs =response.data.log;
    }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });
  }

  backClicked() {
    this._location.back();
  }


  approveReject(id: any, status: any) {


    if (status == 2) {
      let dialogRef ;
       if (this.details.is_wfh === 1) {
          dialogRef = this.dialog.open(ConfirmationDialogComponent, {
           width: "100%",
           data: "Do you confirm the approve of this WFH?",
         });
       } else {
          dialogRef = this.dialog.open(ConfirmationDialogComponent, {
           width: "100%",
           data: "Do you confirm the approve of this leave?",
         });
       }


      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          let obj = {
            'status': status,
            'id': id,
            'user_id': this.id
          };

        

          this.dashboardService.approveReject(obj).subscribe(response => {
            if (response.status_code == 200) {

            
              this.ngOnInit();
            } else {
              this.message = "No Data Found";
              setTimeout(() => {
                this.message = null;
              }, 2000);
            }
          }, error => {
            if (error && error.status === 401) {

              this.router.navigate(['/login'])
            } else {
              this.errorMessage = error;
              if (error.message != undefined) this.errorMessage = error.message;
              setTimeout(() => {
                this.errorMessage = null;
              }, 2000);
            }
          });
        }
      });


    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '100%',
        data: "Do you confirm the reject of this data?"
      });


      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          let obj = {
            'status': status,
            'id': id,
            'user_id': this.id
          };

           

          this.dashboardService.approveReject(obj).subscribe(response => {
            if (response.status_code == 200) {

              this.ngOnInit();
           

            } else {
              this.message = "No Data Found";
              setTimeout(() => {
                this.message = null;
              }, 2000);
            }
          }, error => {
            if (error && error.status === 401) {

              this.router.navigate(['/login'])
            } else {
              this.errorMessage = error;
              if (error.message != undefined) this.errorMessage = error.message;
              setTimeout(() => {
                this.errorMessage = null;
              }, 2000);
            }
          });
        }
      });



    }


  }



  openModal(template: TemplateRef<any>, leave_list, status) {
 
    this.leave_id = leave_list.id;
    this.leave_list = leave_list;



    this.LeaveRejectForm = this.formBuilder.group({
      reason: ['', Validators.required],
      id: this.leave_id,
      status: status,
      user_id: this.id
    });

    this.modalRef = this.modalService.show(template);
  }

  
  get f() { return this.LeaveRejectForm.controls; }

  RejectLeave(data) {
    this.submitted = true;
    if (this.LeaveRejectForm.invalid) {
      return;
    } else {
      let obj = this.LeaveRejectForm.value;

      this.dashboardService.RejectLeave(obj).subscribe(response => {
        if (response.status_code == 200) {
          this.modalRef.hide();
          this.ngOnInit();
         
        } else {
          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      }, error => {
        if (error && error.status === 401) {
          this.router.navigate(['/login'])
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      });



    }
  }



}
