import { environment } from './../../environments/environment';
import { Component, ViewChild, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';

/** Services List */
import { DashboardService } from '../base/services/dashboard.service';
import { CommonService } from '../base/services/common.service';
import { formatDate, DatePipe } from '@angular/common';
import { ConfirmationDialogComponent } from '../components/shared/confirmation-dialog/confirmation-dialog.component';
import { ClockPickerConfig } from 'ng-clock-picker-lib';
import { BsDatepickerViewMode, BsDatepickerConfig } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css'],
  providers: [DatePipe]
})


export class AttendanceComponent implements OnInit {
  @ViewChild('addattendance') addattendance: TemplateRef<any>;
  @ViewChild('attendance') attendance: TemplateRef<any>;

  @ViewChild('EditAttendance') EditAttendance: TemplateRef<any>;

  modalRef: BsModalRef;
  modalAttendance: BsModalRef;
  modaleditAttendance: BsModalRef;
  modalEditAttendance: BsModalRef;

  attendanceForm: FormGroup;
  attendanceFormAdd: FormGroup;
  attendanceEditSingle: FormGroup;
  submitted = false;
  id: any;
  message: string;
  errorMessage: any;
  modelDate = '';
  AllEmployeeCode: any;
  today = new Date();
  employee: any = '';
  month: any = '';
  attendanceList: any;
  attendanceFormEdit: FormGroup;
  checkedList: any = [];
  // summary:any = [];
  selectedAll: any;

  masterSelected: boolean;
  checklist: any;


  EditDate: any;
  INtime: any;
  OutTime: any;
  emp_code: any;
  searchStatus: any;


  sortOrder: string = 'desc';
  column: string = 'attendance_employee.id';
  sortClass: string = 'asc';

  p: number = 1;

  config: ClockPickerConfig = {
    initialValue: '10:30',
    is24: true,
  };



  configout: ClockPickerConfig = {
    initialValue: '19:30',
    is24: true,
    // closeOnOverlayClick: false,

  };
  constructor(public dialog: MatDialog, private commonService: CommonService, private formBuilder: FormBuilder, private dashboardService: DashboardService, private router: Router, private route: ActivatedRoute, private modalService: BsModalService, private datePipe: DatePipe) {
    this.id = localStorage.getItem('id');
    this.masterSelected = false;

    // this.commonService.$castRole.subscribe(val => {
    //   if (val != 'Admin') {
    //     localStorage.clear();
    //     this.router.navigate(['/login'])
    //   }
    // });

    // this.employeeCode();
  }

  ngOnInit() {

    this.attendanceForm = this.formBuilder.group({
      // month: ['', Validators.required],
      attendanceMonth: ['', Validators.required],
      fileCsv: [null, Validators.required],
      avatar: null,
      user_id: this.id
    });


    let obj = {
      'month': this.month,
      'employee_code': this.employee,
      'status': this.searchStatus,
      "sort_order": this.sortOrder,
      "column": this.column
    };
       this.employeeCode();

    this.AllEmployeeAttendance(obj);

    this.attendanceFormAdd = this.formBuilder.group({
      employee_code: ['', Validators.required],
      date: ['', Validators.required],
      in_time: [null, Validators.required],
      out_time: ['', Validators.required],
      user_id: this.id
    });


  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }


  AddAttendance() {
    this.attendanceFormAdd.reset();
    this.modalRef = this.modalService.show(this.addattendance, {
      // class: "logoutClass",
    });
  }



  Attendance() {
    this.modalAttendance = this.modalService.show(this.attendance, {
      // class: "logoutClass",
    });
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.attendanceForm.get('avatar').setValue({
          filename: file.name,
          filetype: file.type,
          value: (<string>reader.result).split(',')[1]
        })
      };
    }
  }

  get f() { return this.attendanceForm.controls; }


  onSubmit() {
    this.submitted = true;
    if (this.attendanceForm.invalid) {
      return;
    } else {
      let obj = this.attendanceForm.value;
    
      this.dashboardService.addAttendance(obj).subscribe(response => {
        if (response.status_code == 200) {
          this.modalRef.hide();
          this.attendanceForm.reset();
          this.ngOnInit();
        } else {
          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      }, error => {
        if (error && error.status === 401) {

          this.router.navigate(['/login'])
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      });
    }
  }


  get g() { return this.attendanceFormAdd.controls; }

  saveAttendance(data) {


    this.submitted = true;
    if (this.attendanceFormAdd.invalid) {
      return;
    } else {


      let obj = {
        'date':this.datePipe.transform(data.value.date, 'yyyy-MM-dd'),
        'employee_code' : data.value.employee_code,
        'in_time' : data.value.in_time,
        'out_time' :data.value.out_time,
        'user_id' : data.value.user_id

      };
     
      this.dashboardService.saveEmployeeAttendance(obj).subscribe(response => {
        if (response.status_code == 200) {
          if (this.modalAttendance) {
            this.modalAttendance.hide();
          }

          this.attendanceFormAdd.reset();
          this.ngOnInit();

        } else {
          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      }, error => {
        if (error && error.status === 401) {

          this.router.navigate(['/login'])
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      });

    }
  }



  employeeCode() {
    this.dashboardService.allEmployeeCode().subscribe(response => {
      if (response.status_code == 200) {
        // this.employee = response.data[0].employee_code;
        // this.month =  new Date(),
        this.AllEmployeeCode = response.data;
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {

        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }




  searchFilter(status_val: any, type: any) {

    if (type == 'employee') {
      this.employee = status_val.target.value;
    } else if (type == 'month') {
      this.month = status_val;
    } else if (type == 'status') {

      this.searchStatus = status_val.target.value;
    }

    let obj = {
      'month': this.datePipe.transform(this.month, 'yyyy-MM-dd'),
      'employee_code': this.employee,
      'status': this.searchStatus,
      "sort_order": this.sortOrder,
      "column": this.column
    };

    this.checkedList = [];
    this.masterSelected = false;
    this.AllEmployeeAttendance(obj);
  }




  sortData(order: string, column: string): void {
    if (order == 'desc') {
      this.sortOrder = 'asc';
      this.sortClass = 'asc';
    }
    if (order == 'asc') {
      this.sortOrder = 'desc';
      this.sortClass = 'desc';
    }
   
    this.column = column;

    let obj = {
      'month': this.datePipe.transform(this.month, 'yyyy-MM-dd'),
      'employee_code': this.employee,
      'status': this.searchStatus,
      "sort_order": this.sortOrder,
      "column": this.column
    };

    this.AllEmployeeAttendance(obj);
  }



  AllEmployeeAttendance(obj: any = null) {

    this.dashboardService.allEmployeeAttendance(obj).subscribe(response => {
      if (response.status_code == 200) {
         

        this.attendanceList = response.data;
      
        
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {

        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });
  }


  checkUncheckAll() {
    for (var i = 0; i < this.attendanceList.length; i++) {
      this.attendanceList[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }


  isAllSelected() {
    this.masterSelected = this.attendanceList.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }


  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.attendanceList.length; i++) {
      if (this.attendanceList[i].isSelected)
        this.checkedList.push(this.attendanceList[i].id);
    }
    
  }


  actionPerform(event) {

   
    // let dialogRef ='';
    if (event.target.value == 'Delete') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '100%',
        data: "Do you confirm the deletion of this data?"
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          let obj = {
            'action': event.target.value,
            'id': this.checkedList
          };

    

          this.dashboardService.deleteVerify(obj).subscribe(response => {
            if (response.status_code == 200) {

              this.ngOnInit();

            } else {
              this.message = "No Data Found";
              setTimeout(() => {
                this.message = null;
              }, 2000);
            }
          }, error => {
            if (error && error.status === 401) {

              this.router.navigate(['/login'])
            } else {
              this.errorMessage = error;
              if (error.message != undefined) this.errorMessage = error.message;
              setTimeout(() => {
                this.errorMessage = null;
              }, 2000);
            }
          });





        }
      });

    }
    if (event.target.value == 'Verify') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '100%',
        data: "Do you confirm the verification of this data?"
      });


      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          let obj = {
            'action': event.target.value,
            'id': this.checkedList
          };

    

          this.dashboardService.deleteVerify(obj).subscribe(response => {
            if (response.status_code == 200) {

              this.ngOnInit();

            } else {
              this.message = "No Data Found";
              setTimeout(() => {
                this.message = null;
              }, 2000);
            }
          }, error => {
            if (error && error.status === 401) {

              this.router.navigate(['/login'])
            } else {
              this.errorMessage = error;
              if (error.message != undefined) this.errorMessage = error.message;
              setTimeout(() => {
                this.errorMessage = null;
              }, 2000);
            }
          });





        }
      });

    }



  }



  editAttendance(singleAttendance: any) {

 

    this.EditDate = singleAttendance.date;
    this.INtime = singleAttendance.in_time;
    this.OutTime = singleAttendance.out_time;
    this.emp_code = singleAttendance.employee_code;


    this.attendanceEditSingle = this.formBuilder.group({
      employee_code: this.emp_code,
      att_date: new Date(this.EditDate),
      in_time: [this.INtime, Validators.required],
      out_time: [this.OutTime, Validators.required],
      user_id: this.id,
      id: singleAttendance.id
    });

    this.config = {
      initialValue: this.INtime,
      is24: true,
    };

    this.configout = {
      initialValue: this.OutTime,
      is24: true,
      // closeOnOverlayClick: false,

    };


    this.modalEditAttendance = this.modalService.show(this.EditAttendance, {
      // class: "logoutClass",
    });



  }


  get t() { return this.attendanceEditSingle.controls; }



  UpdateAttendance() {


    this.submitted = true;
    if (this.attendanceEditSingle.invalid) {
      return;
    } else {
      let obj = this.attendanceEditSingle.value;

       
      this.dashboardService.editAttendance(obj).subscribe(response => {
        if (response.status_code == 200) {

        
          this.modalEditAttendance.hide();
          this.ngOnInit();

        } else {
          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      }, error => {
        if (error && error.status === 401) {

          this.router.navigate(['/login'])
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      });

    }
  }





}
