import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../base/services/dashboard.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CommonService } from '../base/services/common.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../components/shared/confirmation-dialog/confirmation-dialog.component';
import { FlashMessagesService } from 'angular2-flash-messages';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-leave',
  templateUrl: './leave.component.html',
  styleUrls: ['./leave.component.css'],
  providers: [DatePipe],
})
export class LeaveComponent implements OnInit {
  @ViewChild('leave') leave: TemplateRef<any>;
  @ViewChild('template') template: TemplateRef<any>;

  LeaveAdd: FormGroup;
  LeaveRejectForm: FormGroup;

  modalleave: BsModalRef;
  modalRef: BsModalRef;
  submitted = false;
  message: string;
  errorMessage: any;
  id: string;
  employee_code: string;

  hideSummary: boolean = true;

  sortOrder: string = 'desc';
  column: string = 'id';
  sortClass: string = 'asc';
  leaveList: any;

  p: number = 1;
  employee: any = null;
  month: any;
  AllEmployeeCode: any;
  showFullHalfDay: boolean;
  leave_id: any;
  leave_list: any;
  status: any;
  minDate: any = new Date();

  maxDate: any;

  holiday: any = new Date();
  holidays: any = [];
  leave_date: any = [];

  constructor(
    private datePipe: DatePipe,
    private flashMessage: FlashMessagesService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) {
    this.id = localStorage.getItem('id');
    this.employee_code = localStorage.getItem('employee_code');
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 90);
    this.minDate.setDate(this.minDate.getDate() - 45);
    //  this.holidays =[ this.holiday.setDate(this.holiday.getDate() +1 ) ];
    // this.leaveList = [];

    this.holidayArray();
  }

  ngOnInit() {
    this.employeeCode();

    if (this.router.url == '/my-leave') {
      this.hideSummary = false;
      let obj = {
        month: this.datePipe.transform(this.month, 'yyyy-MM-dd'),
        employee_code: this.employee_code,
        sort_order: this.sortOrder,
        column: this.column,
      };
      setTimeout(() => {
        this.allLeave(obj);
      }, 1000);
    } else {
      let obj = {
        month: this.datePipe.transform(this.month, 'yyyy-MM-dd'),
        employee_code: this.employee,
        sort_order: this.sortOrder,
        column: this.column,
      };
      setTimeout(() => {
        this.allLeave(obj);
      }, 10);
    }

    this.LeaveAdd = this.formBuilder.group({
      date: ['', Validators.required],
      type: ['1', Validators.required],
      description: ['', Validators.required],
      time: ['1'],
      user_id: this.id,
      employee_code: this.employee_code,
    });
  }

  searchFilter(status_val: any, type: any) {
    if (type == 'employee') {
      this.employee = status_val.target.value;
    } else if (type == 'month') {
      this.month = status_val;
    } else if (type == 'status') {
      this.status = status_val.target.value;
    }

    if (this.router.url == '/my-leave') {
      let obj = {
        month: this.datePipe.transform(this.month, 'yyyy-MM-dd'),
        employee_code: this.employee_code,
        status: this.status,
        sort_order: this.sortOrder,
        column: this.column,
      };
      if (status_val != null) {
        this.allLeave(obj);
      }
    } else {
      let obj = {
        month: this.datePipe.transform(this.month, 'yyyy-MM-dd'),
        employee_code: this.employee,
        status: this.status,
        sort_order: this.sortOrder,
        column: this.column,
      };
      if (status_val != null) {
        this.allLeave(obj);
      }
    }
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  handleChange(event: any) {
    if (event.target.value == 2) {
      this.showFullHalfDay = true;
    } else {
      this.showFullHalfDay = false;
    }
  }

  AddLeave() {
    this.modalleave = this.modalService.show(this.leave, {});
  }

  get g() {
    return this.LeaveAdd.controls;
  }

  saveLeave(data) {
   
    this.submitted = true;
    if (this.LeaveAdd.invalid) {
      return;
    } else {
      // let obj = this.LeaveAdd.value;
     

      if (this.LeaveAdd.value.type == 1) {
        this.leave_date = [];
        this.LeaveAdd.value.date.forEach((element) => {
          this.leave_date.push(this.datePipe.transform(element, 'yyyy-MM-dd'));
        });
      } else {
        this.leave_date = this.datePipe.transform(
          this.LeaveAdd.value.date,
          'yyyy-MM-dd'
        );
      }

      let obj = {
        date: this.leave_date,
        type: this.LeaveAdd.value.type,
        description: this.LeaveAdd.value.description,
        time: this.LeaveAdd.value.time,
        user_id: this.LeaveAdd.value.user_id,
        employee_code: this.LeaveAdd.value.employee_code,
      };
      

      // return;
      this.dashboardService.saveLeave(obj).subscribe(
        (response) => {
          if (response.status_code == 200) {
            this.modalleave.hide();

            this.leave_date = [];
            this.LeaveAdd.reset();

            this.ngOnInit();
          } else {
            this.message = 'No Data Found';
            setTimeout(() => {
              this.message = null;
            }, 2000);
          }
        },
        (error) => {
          if (error && error.status === '401') {
            this.router.navigate(['/login']);
          } else {
            this.leave_date = [];
            this.errorMessage = error;
            if (error.message != undefined) this.errorMessage = error.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 2000);
          }
        }
      );
    }
  }

  sortData(order: string, column: string): void {
    if (order == 'desc') {
      this.sortOrder = 'asc';
      this.sortClass = 'asc';
    }
    if (order == 'asc') {
      this.sortOrder = 'desc';
      this.sortClass = 'desc';
    }
 
    this.column = column;

    if (this.router.url == '/my-leave') {
      let obj = {
        month: this.month,
        employee_code: this.employee_code,
        sort_order: this.sortOrder,
        column: this.column,
      };
      this.allLeave(obj);
    } else {
      let obj = {
        month: this.month,
        employee_code: this.employee,
        sort_order: this.sortOrder,
        column: this.column,
      };
      this.allLeave(obj);
    }
  }

  allLeave(obj: any = null) {
    this.dashboardService.allLeave(obj).subscribe(
      (response) => {
        if (response.status_code == 200) {
    
          this.leaveList = response.data;
        } else {
          this.message = 'No Data Found';
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      },
      (error) => {
        if (error && error.status === 401) {
          this.router.navigate(['/login']);
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      }
    );
  }

  approveReject(id: any, status: any) {
    if (status == 2) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '100%',
        data: 'Do you confirm the approve of this leave?',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          let obj = {
            status: status,
            id: id,
            user_id: this.id,
          };

         

          this.dashboardService.approveReject(obj).subscribe(
            (response) => {
              if (response.status_code == 200) {
                if (this.router.url == '/my-leave') {
                  let obj = {
                    month: this.month,
                    employee_code: this.employee_code,
                    status: this.status,
                    sort_order: this.sortOrder,
                    column: this.column,
                  };

                  this.allLeave(obj);
                } else {
                  let obj = {
                    month: this.month,
                    employee_code: this.employee,
                    status: this.status,
                    sort_order: this.sortOrder,
                    column: this.column,
                  };

                  this.allLeave(obj);
                }
              } else {
                this.message = 'No Data Found';
                setTimeout(() => {
                  this.message = null;
                }, 2000);
              }
            },
            (error) => {
              if (error && error.status === 401) {
                this.router.navigate(['/login']);
              } else {
                this.errorMessage = error;
                if (error.message != undefined)
                  this.errorMessage = error.message;
                setTimeout(() => {
                  this.errorMessage = null;
                }, 2000);
              }
            }
          );
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '100%',
        data: 'Do you confirm the reject of this data?',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          let obj = {
            status: status,
            id: id,
            user_id: this.id,
          };

           

          this.dashboardService.approveReject(obj).subscribe(
            (response) => {
              if (response.status_code == 200) {
                if (this.router.url == '/my-leave') {
                  let obj = {
                    month: this.month,
                    employee_code: this.employee_code,
                    status: this.status,
                    sort_order: this.sortOrder,
                    column: this.column,
                  };

                  this.allLeave(obj);
                } else {
                  let obj = {
                    month: this.month,
                    employee_code: this.employee,
                    status: this.status,
                    sort_order: this.sortOrder,
                    column: this.column,
                  };

                  this.allLeave(obj);
                }
              } else {
                this.message = 'No Data Found';
                setTimeout(() => {
                  this.message = null;
                }, 2000);
              }
            },
            (error) => {
              if (error && error.status === 401) {
                this.router.navigate(['/login']);
              } else {
                this.errorMessage = error;
                if (error.message != undefined)
                  this.errorMessage = error.message;
                setTimeout(() => {
                  this.errorMessage = null;
                }, 2000);
              }
            }
          );
        }
      });
    }
  }

  employeeCode() {
    this.dashboardService.allEmployeeCode().subscribe(
      (response) => {
        if (response.status_code == 200) {
          this.AllEmployeeCode = response.data;
        } else {
          this.message = 'No Data Found';
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      },
      (error) => {
        if (error && error.status === 401) {
          this.router.navigate(['/login']);
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      }
    );
  }

  openModal(template: TemplateRef<any>, leave_list, status) {
     
    this.leave_id = leave_list.id;
    this.leave_list = leave_list;

    this.LeaveRejectForm = this.formBuilder.group({
      reason: ['', Validators.required],
      id: this.leave_id,
      status: status,
      user_id: this.id,
    });

    this.modalRef = this.modalService.show(template);
  }

  get f() {
    return this.LeaveRejectForm.controls;
  }

  RejectLeave(data) {
    this.submitted = true;
    if (this.LeaveRejectForm.invalid) {
      return;
    } else {
      let obj = this.LeaveRejectForm.value;

      this.dashboardService.RejectLeave(obj).subscribe(
        (response) => {
          if (response.status_code == 200) {
            this.modalRef.hide();

            if (this.router.url == '/my-leave') {
              let obj = {
                month: this.month,
                employee_code: this.employee_code,
                status: this.status,
                sort_order: this.sortOrder,
                column: this.column,
              };

              this.allLeave(obj);
            } else {
              let obj = {
                month: this.month,
                employee_code: this.employee,
                status: this.status,
                sort_order: this.sortOrder,
                column: this.column,
              };

              this.allLeave(obj);
            }
          } else {
            this.message = 'No Data Found';
            setTimeout(() => {
              this.message = null;
            }, 2000);
          }
        },
        (error) => {
          if (error && error.status === 401) {
            this.router.navigate(['/login']);
          } else {
            this.errorMessage = error;
            if (error.message != undefined) this.errorMessage = error.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 2000);
          }
        }
      );
    }
  }

  LeavePage(s_id: any) {
    this.router.navigate(['leave-detail'], { queryParams: { id: s_id } });
  }

  calendar() {
    this.router.navigate(['event-calendar']);
  }

  holidayArray() {
    this.dashboardService.HoildayArray().subscribe(
      (response) => {
        if (response.status_code == 200) {
          response.data.forEach((obj) => {
            this.holidays.push(new Date(obj));
          });

      
        } else {
          this.message = 'No Data Found';
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      },
      (error) => {
        if (error && error.status === 401) {
          this.router.navigate(['/login']);
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      }
    );
  }

  deleteLeave(leave_id: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '100%',
      data: 'Do you confirm the delete of this data?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let obj = {
          leave_id: leave_id,
        };

     

        this.dashboardService.deleteLeave(obj).subscribe(
          (response) => {
            if (response.status_code == 200) {
              this.ngOnInit();
            } else {
              this.message = 'No Data Found';
              setTimeout(() => {
                this.message = null;
              }, 2000);
            }
          },
          (error) => {
            if (error && error.status === 401) {
              this.router.navigate(['/login']);
            } else {
              this.errorMessage = error;
              if (error.message != undefined) this.errorMessage = error.message;
              setTimeout(() => {
                this.errorMessage = null;
              }, 2000);
            }
          }
        );
      }
    });
  }
}
