import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ElementRef,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "../base/services/dashboard.service";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { CommonService } from "../base/services/common.service";
import { MatDialog } from "@angular/material";
import { FlashMessagesService } from "angular2-flash-messages";
import { DatePipe } from "@angular/common";
import { EditPopupComponent } from "../edit-popup/edit-popup.component";

@Component({
  selector: "app-chatgpt",
  templateUrl: "./chatgpt.component.html",
  styleUrls: ["./chatgpt.component.css"],
  providers: [DatePipe],
})
export class ChatgptComponent implements OnInit {
  @ViewChild("leave") chatgpt: TemplateRef<any>;
  @ViewChild("template") template: TemplateRef<any>;
  @ViewChild("myTextarea") myTextarea: ElementRef;

  searchedText: string = "";
  LeaveAdd: FormGroup;
  LeaveRejectForm: FormGroup;
  filteredChatgptData: any[] = [];
  loading: boolean = false;

  modalleave: BsModalRef;
  modalRef: BsModalRef;
  submitted = false;
  message: string;
  errorMessage: any;
  id: string;
  employee_code: string;
  chatgptData: any[] = [];
  renderData: any[] = [];

  hideSummary: boolean = true;

  sortOrder: string = "desc";
  column: string = "id";
  sortClass: string = "asc";
  leaveList: any;

  p: number = 1;
  employee: any = null;
  month: any;
  AllEmployeeCode: any;
  showFullHalfDay: boolean;
  leave_id: any;
  leave_list: any;
  status: any;
  minDate: any = new Date();
  searchStatus: any;

  maxDate: any;

  holiday: any = new Date();
  holidays: any = [];
  leave_date: any = [];
  inputValue: any;
  chatGPTResponse: any;
  pagedChatgptData: any[] = [];

  sortData(order: string, field: string) {
    if (order == "desc") {
      this.sortOrder = "asc";
      this.sortClass = "asc";
    }
    if (order == "asc") {
      this.sortOrder = "desc";
      this.sortClass = "desc";
    }

    const dataToSort =
      this.employee === "" ? this.chatgptData : this.filteredChatgptData;

    if (
      field === "user_name" ||
      field === "user_message" ||
      field == "created_at"
    ) {
      this.sortOrder = order === "asc" ? "desc" : "asc";

      dataToSort.sort((a, b) => {
        const aValue = a[field].toLowerCase();
        const bValue = b[field].toLowerCase();

        return order === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
    }
  }

  constructor(
    private datePipe: DatePipe,
    private flashMessage: FlashMessagesService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) {
    this.id = localStorage.getItem("id");
    this.employee_code = localStorage.getItem("employee_code");
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 90);
    this.minDate.setDate(this.minDate.getDate() - 45);
  }

  ngOnInit() {
    this.hideSummary = false;
    this.fetchChatgptData();
    setTimeout(() => {}, 1000);
  }

  openEditPopup(item: any) {
    const initialState = {
      userDetails: item,
    };

    this.modalService.show(EditPopupComponent, {
      initialState,
    });
  }

  autoIncrementTextareaHeight() {
    const textarea = this.myTextarea.nativeElement;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }

  search() {
    this.loading = true;

    if (this.inputValue) {
      this.searchedText = this.inputValue;

    

      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const year = currentDate.getFullYear();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes().toString().padStart(2, "0");
      const seconds = currentDate.getSeconds().toString().padStart(2, "0");

      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");

      const formattedDate = `${month}/${day}/${year}, ${formattedHours}:${minutes}:${seconds} ${ampm}`;
      const newChatMessage = {
        user_message: this.inputValue,
        created_at: formattedDate,
      };

      this.renderData.unshift(newChatMessage);
      localStorage.setItem("chatHistory", JSON.stringify(this.renderData));

      const obj = {
        user_name: localStorage.name,
        user_input: this.inputValue,
      };

      this.allChatgpt(obj);

      this.scrollToTop();

      // this.inputValue = "";
    }
  }

  scrollToTop() {
    const chatContainer = document.querySelector(".left-side");
    if (chatContainer) {
      chatContainer.scrollTop = 0;
    }
  }

  fetchChatgptData() {
    this.dashboardService.fetchChatgptData().subscribe(
      (response) => {
        if (response) {
          this.chatgptData = response.data;
          this.chatgptData.reverse();
          this.renderData = this.chatgptData;
        } else {
          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      },
      (error) => {
        if (error && error.status === 401) {
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      }
    );
  }

  allChatgpt(obj: any = null) {
    this.dashboardService.allChatgpt(obj).subscribe(
      (response) => {
        if (response) {
          this.chatGPTResponse = response.chat_gpt_response;
        } else {
          this.message = "No Data Found";
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
        this.loading = false;
      },
      (error) => {
        if (error && error.status === 401) {
          this.router.navigate(["/login"]);
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      }
    );
  }

  populateTextarea(message: string): void {
 
    this.inputValue = message;
  }

  reversedPagedChatgptData(): any[] {
    return this.pagedChatgptData.slice().reverse();
  }
}
