import { Component, ViewChild, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { DashboardService } from '../base/services/dashboard.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../base/services/common.service';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})
export class TicketComponent implements OnInit {

  @ViewChild('template') template: TemplateRef<any>;

  message: string;
  errorMessage: any;
  userSupport: any;
  userSupportLocal: any;
  id: any;
  modalRef: BsModalRef;

  closeing_remark: any;
  support_id: any;
  search_val: any;
  type: any;
  status: any = '';
  query_type:any = '';

  closeForm: FormGroup;
  submitted = false;
  closeValue: any;

  sortOrder: string = 'desc';
  column:string = 'id';
  sortClass: string = 'asc';

  searchStatus:string ='';
  searchPriority:string='';
  searchOther:string='';


  typeArray : any = ['Hardware', 'Software', 'Internet'];
  priorityArray : any = ['High', 'Medium', 'Low'];

  roleArray:any = ['Admin','System Admin'];
  roleShow: boolean;
  

  p: number = 1;


  constructor(private commonService: CommonService,private formBuilder: FormBuilder,private route: ActivatedRoute, private router: Router, private dashboardService: DashboardService, private modalService: BsModalService) {
    this.id = localStorage.getItem('id');
    this.commonService.$castRole.subscribe(val => {
        if(!this.roleArray.includes(val)){
             localStorage.clear();
              this.router.navigate(['/login'])
        } 
    });


  }
  ngOnInit() {
    this.getallticket();
    this.closeForm = this.formBuilder.group({
      status: ['', Validators.required],
      close_remark: ['', Validators.required]
  });
  }

  sortData(order: string,column:string): void {
    if (order == 'desc'){
      this.sortOrder = 'asc';
      this.sortClass = 'asc';
    }
    if (order == 'asc'){
      this.sortOrder = 'desc';
      this.sortClass = 'desc';
    }
    this.column = column;
    this.getallticket();
  }

  searchFilter(status_val: any,type:any) {
   
    // let val = status_val.target.value;
    // if (val && val.length > 0) {
    //   this.userSupport = this.userSupportLocal.filter((opt) => {
    //     return opt.status == val;
    //   });

    // } else {
    //   this.userSupport = Object.assign([], this.userSupportLocal);

    // }

    if(type=='status'){
      this.searchStatus = status_val.target.value;
     
    }else if(type=='priority'){
      this.searchPriority = status_val.target.value;
      
    }else if(type=='other'){
      this.searchOther =  status_val.target.value;
  
    }else{
      this.userSupport = Object.assign([], this.userSupportLocal);
    }

    if(this.searchStatus == '' && this.searchPriority=='' && this.searchOther==''){
      this.userSupport = Object.assign([], this.userSupportLocal);
    }else{
    let obj = {
        'status' :this.searchStatus,
        'priority':this.searchPriority,
        'other':this.searchOther,
        'query_type':2
    };
    this.searchallticket(obj);
    }
  }

  getallticket() {

    let obj = {
      "sort_order": this.sortOrder,
      "column": this.column,
      'query_type':2
    }



    this.dashboardService.GetAllQuery(obj).subscribe(response => {
      if (response.status_code == 200) {
        this.userSupport = response.data;
        this.userSupportLocal = response.data;
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });
  }

  openModal(template: TemplateRef<any>, support_id) {
 
    this.support_id = support_id;
    this.modalRef = this.modalService.show(template);
  }


  get f() { return this.closeForm.controls; }


  saveCloseingRemark() {

    this.submitted = true;
    if (this.closeForm.invalid) {
         return;
    }else{
      this.closeValue= this.closeForm.value;
   
      
      let obj = {
        "closeing_remark": this.closeValue.close_remark,
        "status": this.closeValue.status,
        "support_id": this.support_id
      }

    this.dashboardService.closeremark(obj).subscribe(response => {
      if (response.status_code == 200) {
      
        this.modalRef.hide();
        this.ngOnInit();
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }
  }

  deleteSupport(supportid) {
    let obj = {
      "support_id": supportid
    }

    this.dashboardService.deleteSupport(obj).subscribe(response => {
      if (response.status_code == 200) {
        this.ngOnInit();
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });

  }

  ticketPage(s_id: any) {
    this.router.navigate(['ticket-detail'], { queryParams: { id: s_id } });
  }

  searchallticket(obj:any) {

   
    this.dashboardService.GetSearchQuery(obj).subscribe(response => {
      if (response.status_code == 200) {
        this.userSupport = response.data;
       
      } else {
        this.message = "No Data Found";
        setTimeout(() => {
          this.message = null;
        }, 2000);
      }
    }, error => {
      if (error && error.status === 401) {
        this.router.navigate(['/login'])
      } else {
        this.errorMessage = error;
        if (error.message != undefined) this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = null;
        }, 2000);
      }
    });
  }



}
