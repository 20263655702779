import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { DashboardService } from '../base/services/dashboard.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../components/shared/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.css'],
  providers: [DatePipe],
})
export class HolidaysComponent implements OnInit {
  @ViewChild('holidays') holidays: TemplateRef<any>;

  currentYear: string;
  presentYear: any;
  modalHolidays: BsModalRef;
  holidaysForm: FormGroup;
  submitted = false;
  id: string;
  today = new Date();
  message: string;
  errorMessage: any;
  holidaylist: any;
  originalHolidaylist: any;
  role: string;

  roles: any = ['Admin', 'HR'];
  roleShow: boolean = false;
  month: any;

  sortOrder: string = 'asc';
  column: string = 'id';
  sortClass: string = 'asc';

  p: number = 1;

  year: string = null;

  // generateYears() {
  //   const currentYear = new Date().getFullYear();
  //   const startYear = currentYear - 10;
  //   const endYear = currentYear + 10;

  //   for (let year = startYear; year <= endYear; year++) {
  //     this.years.push(year);
  //   }
  // }

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService
  ) {
    //this.generateYears();

    this.presentYear = moment().year();
    // const currentYear = new Date().getFullYear();
    this.presentYear = this.presentYear.toString();

    this.id = localStorage.getItem('id');
    this.role = localStorage.getItem('role');
    if (this.roles.includes(this.role)) {
      this.roleShow = true;
    }
  }

  ngOnInit() {
    this.holidaysForm = this.formBuilder.group({
      // month: ['', Validators.required],
      date: ['', Validators.required],
      description: ['', Validators.required],
      user_id: this.id,
    });
    const years = new Date().getFullYear();
    this.currentYear = this.datePipe.transform(years, 'yyyy');
    // this.currentYear = this.presentYear.toString();

    let obj = {
      year: this.year,
      sort_order: this.sortOrder,
      column: this.column,
    };

    //this.allHolidays(obj);
    setTimeout(() => {
      this.allHolidays(obj);
    }, 2000);

    const current_year = new Date();

    // this.onYearSelected(current_year);
  }

  sortData(order: string, column: string): void {
    if (order == 'desc') {
      this.sortOrder = 'asc';
      this.sortClass = 'asc';
    }
    if (order == 'asc') {
      this.sortOrder = 'desc';
      this.sortClass = 'desc';
    }

    this.column = column;

    let obj = {
      year: this.year,
      sort_order: this.sortOrder,
      column: this.column,
    };

    this.allHolidays(obj);
  }

  get g() {
    return this.holidaysForm.controls;
  }

  addHolidays() {
    this.modalHolidays = this.modalService.show(this.holidays);
  }

  saveHolidays(data) {
    this.submitted = true;
    if (this.holidaysForm.invalid) {
      return;
    } else {
      let obj = {
        date: this.datePipe.transform(data.value.date, 'yyyy-MM-dd'),
        description: data.value.description,
        user_id: data.value.user_id,
      };

      this.dashboardService.saveHolidays(obj).subscribe(
        (response) => {
          if (response.status_code == 200) {
            this.modalHolidays.hide();
            this.holidaysForm.reset();
            this.ngOnInit();
          } else {
            this.message = 'No Data Found';
            setTimeout(() => {
              this.message = null;
            }, 2000);
          }
        },
        (error) => {
          if (error && error.status === 401) {
            this.router.navigate(['/login']);
          } else {
            this.errorMessage = error;
            if (error.message != undefined) this.errorMessage = error.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 2000);
          }
        }
      );
    }
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  // searchFilter(event: any, filterType: string) {
  //   // Implement your search filter logic here if needed
  //   // For this example, we'll leave it empty
  // }

  onYearSelected(selectedDate: Date): void {
    if (selectedDate) {
      this.year = selectedDate.getFullYear().toString();
    } else {
      this.year = null;
    }

    console.log();
    // Call the searchFilter function with the selected year (or null)
    this.searchFilter(this.year, 'year');
  }

  searchFilter(status_val: any, type: any) {
    if (type === 'year') {
      const selectedYear = parseInt(status_val, 10);

      if (!isNaN(selectedYear) && status_val.length === 4) {
        this.year = status_val;
      } else {
        this.year = null;
      }
    }

    const yearString = this.year || '';

    // Create the object with the updated yearString value and other properties
    let obj = {
      year: yearString,
      sort_order: this.sortOrder,
      column: this.column,
    };

    this.allHolidays(obj);
  }

  allHolidays(obj: any = null, shouldFetchData: boolean = true) {
    if (shouldFetchData) {
      this.dashboardService.allHolidays(obj).subscribe(
        (response) => {
          if (response.status_code === 200) {
            // Store the full list of holidays in a separate variable
            this.holidaylist = response.data.map((holiday) => {
              return {
                ...holiday,
                date: new Date(holiday.date),
              };
            });
          } else {
            this.message = 'No Data Found';
            setTimeout(() => {
              this.message = null;
            }, 2000);
          }
        },
        (error) => {
          if (error && error.status === 401) {
            this.router.navigate(['/login']);
          } else {
            this.errorMessage = error;
            if (error.message != undefined) this.errorMessage = error.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 2000);
          }
        }
      );
    }
  }

  deleteHolidays(holiday_id: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '100%',
      data: 'Do you confirm the deletion of this data?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let obj = {
          holiday_id: holiday_id,
        };

        this.dashboardService.deleteHoliday(obj).subscribe(
          (response) => {
            if (response.status_code == 200) {
              this.ngOnInit();
            } else {
              this.message = 'No Data Found';
              setTimeout(() => {
                this.message = null;
              }, 2000);
            }
          },
          (error) => {
            if (error && error.status === 401) {
              this.router.navigate(['/login']);
            } else {
              this.errorMessage = error;
              if (error.message != undefined) this.errorMessage = error.message;
              setTimeout(() => {
                this.errorMessage = null;
              }, 2000);
            }
          }
        );
      }
    });
  }
}
