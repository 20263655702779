import { Injectable } from '@angular/core';
import { 
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';
// import decode from 'jwt-decode';
@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    const role = localStorage.getItem('role');
   
    if ( !this.auth.isAuthenticated() ||   role !== expectedRole ) {
            localStorage.clear();
            this.router.navigate(['/login'])
    }
    return true;
  }
}