import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../base/services/dashboard.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CommonService } from '../base/services/common.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../components/shared/confirmation-dialog/confirmation-dialog.component';
import { ClockPickerConfig } from 'ng-clock-picker-lib';
import { formatDate, DatePipe } from '@angular/common';
@Component({
  selector: 'app-my-attendance',
  templateUrl: './my-attendance.component.html',
  styleUrls: ['./my-attendance.component.css'],
  providers: [DatePipe],
})
export class MyAttendanceComponent implements OnInit {
  @ViewChild('attendance') attendance: TemplateRef<any>;
  @ViewChild('EditAttendance') EditAttendance: TemplateRef<any>;
  attendanceFormAdd: FormGroup;
  attendanceEditSingle: FormGroup;

  modalAttendance: BsModalRef;
  modalEditAttendance: BsModalRef;
  id: string;
  submitted = false;
  message: string;
  errorMessage: any;
  employee_code: string;
  attendanceList: any;
  month: any = '';
  employee: any;
  checkedList: any = [];
  selectedAll: any;

  masterSelected: boolean;
  checklist: any;
  EditDate: any;
  INtime: any;
  OutTime: any;
  today = new Date();
  searchStatus: any;

  sortOrder: string = 'desc';
  column: string = 'attendance_employee.id';
  sortClass: string = 'asc';

  p: number = 1;

  config: ClockPickerConfig = {
    initialValue: '10:30',
    is24: true,
  };

  configout: ClockPickerConfig = {
    initialValue: '19:30',
    is24: true,
    closeOnOverlayClick: false,
  };

  constructor(
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) {
    this.id = localStorage.getItem('id');
    this.employee_code = localStorage.getItem('employee_code');
    this.masterSelected = false;
  }

  ngOnInit() {
   
    this.attendanceFormAdd = this.formBuilder.group({
      employee_code: this.employee_code,
      date: [null, Validators.required],
      in_time: ['', Validators.required],
      out_time: [null, Validators.required],
      user_id: this.id,
    });

    let obj = {
      month: this.month,
      employee_code: this.employee_code,
      sort_order: this.sortOrder,
      column: this.column,
    };

    this.AllEmployeeAttendance(obj);
  }

  sortData(order: string, column: string): void {
    if (order == 'desc') {
      this.sortOrder = 'asc';
      this.sortClass = 'asc';
    }
    if (order == 'asc') {
      this.sortOrder = 'desc';
      this.sortClass = 'desc';
    }
 
    this.column = column;

    let obj = {
      month: this.datePipe.transform(this.month, 'yyyy-MM-dd'),
      employee_code: this.employee_code,
      status: this.searchStatus,
      sort_order: this.sortOrder,
      column: this.column,
    };

    this.AllEmployeeAttendance(obj);
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  searchFilter(status_val: any, type: any) {
    if (type == 'month') {
      this.month = status_val;
    } else if (type == 'status') {
      this.searchStatus = status_val.target.value;
    }
    let obj = {
      month: this.datePipe.transform(this.month, 'yyyy-MM-dd'),
      employee_code: this.employee_code,
      status: this.searchStatus,
      sort_order: this.sortOrder,
      column: this.column,
    };

    this.AllEmployeeAttendance(obj);
  }

  Attendance() {
    this.modalAttendance = this.modalService.show(this.attendance, {
      // class: "logoutClass",
    });
  }

  get g() {
    return this.attendanceFormAdd.controls;
  }

  saveAttendance() {
    this.submitted = true;
    if (this.attendanceFormAdd.invalid) {
      return;
    } else {
      // let obj = this.attendanceFormAdd.value;

      let obj = {
        date: this.datePipe.transform(
          this.attendanceFormAdd.value.date,
          'yyyy-MM-dd'
        ),
        employee_code: this.attendanceFormAdd.value.employee_code,
        in_time: this.attendanceFormAdd.value.in_time,
        out_time: this.attendanceFormAdd.value.out_time,
        user_id: this.attendanceFormAdd.value.user_id,
      };

      this.dashboardService.saveEmployeeAttendance(obj).subscribe(
        (response) => {
          if (response.status_code == 200) {
            this.modalAttendance.hide();
            this.attendanceFormAdd.reset();
            this.ngOnInit();
          } else {
            this.message = 'No Data Found';
            setTimeout(() => {
              this.message = null;
            }, 2000);
          }
        },
        (error) => {
          if (error && error.status === 401) {
            this.router.navigate(['/login']);
          } else {
            this.errorMessage = error;
            if (error.message != undefined) this.errorMessage = error.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 2000);
          }
        }
      );
    }
  }

  AllEmployeeAttendance(obj: any = null) {
    this.dashboardService.allEmployeeAttendance(obj).subscribe(
      (response) => {
        if (response.status_code == 200) {
    
          if(!this.month){
            this.month = new Date();
          }
      
   
          this.attendanceList = response.data;
        } else {
          this.message = 'No Data Found';
          setTimeout(() => {
            this.message = null;
          }, 2000);
        }
      },
      (error) => {
        if (error && error.status === 401) {
          this.router.navigate(['/login']);
        } else {
          this.errorMessage = error;
          if (error.message != undefined) this.errorMessage = error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      }
    );
  }

  checkUncheckAll() {
    for (var i = 0; i < this.attendanceList.length; i++) {
      this.attendanceList[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    this.masterSelected = this.attendanceList.every(function (item: any) {
      return item.isSelected == true;
    });
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (var i = 0; i < this.attendanceList.length; i++) {
      if (this.attendanceList[i].isSelected)
        this.checkedList.push(this.attendanceList[i].id);
    }
    // this.checkedList = JSON.stringify(this.checkedList);

    
  }

  actionPerform(event) {
    if (event.target.value == 'Delete') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '100%',
        data: 'Do you confirm the deletion of this data?',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          let obj = {
            action: event.target.value,
            id: this.checkedList,
          };

        

          this.dashboardService.deleteVerify(obj).subscribe(
            (response) => {
              if (response.status_code == 200) {
                this.ngOnInit();
              } else {
                this.message = 'No Data Found';
                setTimeout(() => {
                  this.message = null;
                }, 2000);
              }
            },
            (error) => {
              if (error && error.status === 401) {
                this.router.navigate(['/login']);
              } else {
                this.errorMessage = error;
                if (error.message != undefined)
                  this.errorMessage = error.message;
                setTimeout(() => {
                  this.errorMessage = null;
                }, 2000);
              }
            }
          );
        }
      });
    }
    if (event.target.value == 'Verify') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '100%',
        data: 'Do you confirm the verification of this data?',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          let obj = {
            action: event.target.value,
            id: this.checkedList,
          };

         

          this.dashboardService.deleteVerify(obj).subscribe(
            (response) => {
              if (response.status_code == 200) {
                this.ngOnInit();
              } else {
                this.message = 'No Data Found';
                setTimeout(() => {
                  this.message = null;
                }, 2000);
              }
            },
            (error) => {
              if (error && error.status === 401) {
                this.router.navigate(['/login']);
              } else {
                this.errorMessage = error;
                if (error.message != undefined)
                  this.errorMessage = error.message;
                setTimeout(() => {
                  this.errorMessage = null;
                }, 2000);
              }
            }
          );
        }
      });
    }
  }

  editAttendance(singleAttendance: any) {
    

    this.EditDate = singleAttendance.date;
    this.INtime = singleAttendance.in_time;
    this.OutTime = singleAttendance.out_time;

    this.attendanceEditSingle = this.formBuilder.group({
      employee_code: this.employee_code,
      att_date: new Date(this.EditDate),
      in_time: [this.INtime, Validators.required],
      out_time: [this.OutTime, Validators.required],
      user_id: this.id,
      id: singleAttendance.id,
    });

    this.config = {
      initialValue: this.INtime,
      is24: true,
    };

    this.configout = {
      initialValue: this.OutTime,
      is24: true,
      // closeOnOverlayClick: false,
    };

    this.modalEditAttendance = this.modalService.show(this.EditAttendance, {
      // class: "logoutClass",
    });
  }

  get t() {
    return this.attendanceEditSingle.controls;
  }

  UpdateAttendance() {
    this.submitted = true;
    if (this.attendanceEditSingle.invalid) {
      return;
    } else {
      let obj = this.attendanceEditSingle.value;

     
      this.dashboardService.editAttendance(obj).subscribe(
        (response) => {
          if (response.status_code == 200) {
            
            this.modalEditAttendance.hide();
            this.ngOnInit();
          } else {
            this.message = 'No Data Found';
            setTimeout(() => {
              this.message = null;
            }, 2000);
          }
        },
        (error) => {
          if (error && error.status === 401) {
            this.router.navigate(['/login']);
          } else {
            this.errorMessage = error;
            if (error.message != undefined) this.errorMessage = error.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 2000);
          }
        }
      );
    }
  }
}
